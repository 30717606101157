
import axios from '@/axios'
import store from '@/store/store.js'
import appConfig from '../app.config.js'

const actions = {
  add (type, result, user_id = null, portal_id = null, project_id = null, layer_id = null, session_id = null) {
    if (user_id == null) {
      user_id = `${store.state.AppActiveUser.user_id}`
    }
    if (portal_id == null) {
      portal_id = `${store.state.portal_id}`
    }
    if (session_id == null) {
      session_id = `${store.state.session_id}`
    }

    let is_ghost = false
    if (typeof store.state.current_user_global_caps.admin_caps == 'object') {
      is_ghost = store.state.current_user_global_caps.admin_caps.is_ghost == 1
    }
    if (!is_ghost) {
      const params = new FormData()
      params.set('session_id', session_id)
      params.set('user_id', user_id)
      params.set('portal_id', portal_id)
      params.set('project_id', project_id)
      params.set('layer_id', layer_id)
      params.set('type', type)
      params.set('result', result)
      const rimnat_api_call_url = '/user/logUserActivity'
      axios.post(appConfig.apiBaseUrl + rimnat_api_call_url, params)
        .then(() => { })
        .catch((error)   => { console.log(error) })
    } else {
      console.info('you have ghost status, your activities are not logged by logActivity.js')
    }
  }
}
export default actions