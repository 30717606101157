// axios
import axios from 'axios'
import appConfig from '../app.config.js'

const baseURL = ''
const timestamp = new Date().getTime()

export default axios.create({
  baseURL,
  headers: {
    // New header
    'X-Rimnat-AppId' : appConfig.rimnatAppId,
    'X-Rimnat-Timestamp':  timestamp,
    //TODO : Old header - to remove
    'rimnatuser':  'app-2022-05-02-EHN3JtFMpn',
    'rimnatpass':  'pass-ZM8babuwyLpeDSHXlr6gNWT',
    'rimnattimestamp':  timestamp
  }
})
