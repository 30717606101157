<!-- =========================================================================================
    File Name: user-avatar.vue
    Description: User avatar component
    ----------------------------------------------------------------------------------------
    Item Name: RIM-Nat / Plateforme 3D collaborative

    Author URL:
========================================================================================== -->

<template>
  <div v-if="typeof this.user !== undefined">
    <vs-avatar v-if="this.imageFound === true" 
      :key="'avatar_'+user.user_id+'_v_'+actualisation+'_s_'+this.$store.state.current_user_avatar_increment"
      :id="'avatar_'+user.user_id+'_v_'+actualisation"
      :src="this.avatarUrl"
      :size="this.size" 
    />
    <vs-avatar v-if="this.imageFound === false"
      :key="'avatar_'+user.user_id+'_v_'+actualisation+'_s_'+this.$store.state.current_user_avatar_increment"
      :id="'avatar_'+user.user_id+'_v_'+actualisation"
      :text="userTextAvatar()"
      :color="avatarColor()"
      :size="this.size" 
    />
  </div>
</template>

<script>

export default {
  name: 'user-avatar',
  props: {
    user: {
    },
    size: {
    },
    actualisation: {
    }
  },

  components: {
  },
  data () {
    return {
      avatarUrl: '', 
      imageFound: null
    }
  },
  created () {
    this.getAvatarUrl()
  },
  methods: {
    // ROYBON / récupère et teste l'URL de l'image
    getAvatarUrl () {
      this.avatarUrl = `${this.$appConfig.imgBaseUrl}/user/avatar/${this.user.user_id}/thumb_150_150.jpg?v=${this.$store.state.current_user_avatar_increment}`
      this.$http.get(this.avatarUrl)
        .then((response) => { 
          this.imageFound = response.status === 200
        })
        .catch(() => {
          this.imageFound = false
        })
    },
    // ROYBON / génère un username complet
    userTextAvatar () {
      let firstName = this.user.first_name || '-'
      let lastName = this.user.last_name || '-'
      firstName = firstName.substring(0, 1).toUpperCase()
      lastName = lastName.substring(0, 3).toUpperCase()
      return `${firstName}.${lastName}`
    },
    // ROYBON / génère une couleur unique pour un user_id
    avatarColor () {
      return `#${this.intToRGB(this.hashCode(this.user.user_id.toString()))}`
    },
    hashCode (str) {
      let hash = 0
      if (typeof str !== undefined) {
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
      }
      return hash
    },
    intToRGB (i) {
      const c = (Math.pow(i, 2) & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
      return '00000'.substring(0, 6 - c.length) + c
    }

  }
}

</script>

<style lang="scss">
  .vs-avatar--con-img {
    left: -2px;
    top: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
  }
</style>