/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
==========================================================================================*/
const mutations = {
  // /////////////////////////////////////////////
  // RIM-Nat
  // /////////////////////////////////////////////
  // ipData
  UPDATE_IP_DATA (state, data) {
    state.ipData = data
  },
  // user configuration diagnostic
  UPDATE_USER_DIAGNOSTIC (state, data) {
    state.userDiagnostic = data
  },
  // portals
  UPDATE_SESSION_ID (state, session_id) {
    state.session_id = session_id
  },
  // portals
  UPDATE_PORTALS (state, portals) {
    state.portals = portals
  },
  // portal_id
  UPDATE_PORTAL_ID (state, portal_id) {
    state.portal_id = portal_id
  },
  // portal_slug
  UPDATE_PORTAL_SLUG (state, portal_slug) {
    state.portal_slug = portal_slug
  },
  // portal_nicename
  UPDATE_PORTAL_NICENAME (state, portal_nicename) {
    state.portal_nicename = portal_nicename
  },
  // params
  UPDATE_PORTAL_PARAMS (state, portal) {
    state.portal = portal
  },
  // liste des projets dans le champ de recherche
  UPDATE_PROJECTS_SEARCH_LIST (state, projects_search_list) {
    state.projects_search_list.pages.data = projects_search_list
  },
  // current_user_global_caps
  UPDATE_CURRENT_USER_GLOBAL_CAPS (state, current_user_global_caps) {
    state.current_user_global_caps = current_user_global_caps
  },
  // current_user_portal_caps
  UPDATE_CURRENT_USER_PORTAL_CAPS (state, current_user_portal_caps) {
    state.current_user_portal_caps = current_user_portal_caps
  },
  // projects_parameters
  UPDATE_PROJECTS_LOCATION (state, value) {
    state.projectsLocation = value
  },
  UPDATE_PROJECTS_LEGEND (state, value) {
    state.projectsLegend = value
  },  
  UPDATE_PROJECTS_COORDINATES_SYSTEM (state, value) {
    state.projectsCoordinatesSystem = value
  },
  UPDATE_BETA_TESTER (state, value) {
    state.betaTester = value
  },
  UPDATE_RDP (state, value) {
    state.rdp = value
  },
  UPDATE_VIEWER_VERSION (state, value) {
    state.viewerVersion = value
  },
  // subscription usage
  UPDATE_SUSBSCRIPTION_USAGE (state, usageObject) {
    state.subscriptionUsage.timeStart                   = usageObject.timeStart          
    state.subscriptionUsage.timeEnd                     = usageObject.timeEnd            
    state.subscriptionUsage.timeUsedPercent             = usageObject.timeUsedPercent    
    state.subscriptionUsage.timeDaysAvailable           = usageObject.timeDaysAvailable  
    state.subscriptionUsage.spaceUsedPercent            = usageObject.spaceUsedPercent   
    state.subscriptionUsage.spaceUsed                   = usageObject.spaceUsed          
    state.subscriptionUsage.spaceTotal                  = usageObject.spaceTotal         
    state.subscriptionUsage.layersTotal                 = usageObject.layersTotal        
    state.subscriptionUsage.layersUsed                  = usageObject.layersUsed         
    state.subscriptionUsage.layersAvailable             = usageObject.layersAvailable    
    state.subscriptionUsage.layersUsedPercent           = usageObject.layersUsedPercent  
    state.subscriptionUsage.projectsTotal               = usageObject.projectsTotal      
    state.subscriptionUsage.projectsUsed                = usageObject.projectsUsed       
    state.subscriptionUsage.projectsAvailable           = usageObject.projectsAvailable  
    state.subscriptionUsage.projectsUsedPercent         = usageObject.projectsUsedPercent
    state.subscriptionUsage.usersTotal                  = usageObject.usersTotal         
    state.subscriptionUsage.usersUsed                   = usageObject.usersUsed          
    state.subscriptionUsage.usersAvailable              = usageObject.usersAvailable     
    state.subscriptionUsage.usersUsedPercent            = usageObject.usersUsedPercent   
  },

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE (state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON (state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE (state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN (state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH (state, width) {
    state.verticalNavMenuWidth = width
  },


  // VxAutoSuggest
  UPDATE_STARRED_PAGE (state, payload) {

    // find item index in search list state
    const index = state.navbarSearchAndPinList['pages'].data.findIndex((item) => item.url === payload.url)

    // update the main list
    state.navbarSearchAndPinList['pages'].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList['pages'].data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url === payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED (state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages     = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE (state, list) {
    let downToUp                   = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited      = state.starredPages.slice(0, 10)
    state.starredPages             = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },


  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY (state, val) { state.bodyOverlay       = val   },
  UPDATE_PRIMARY_COLOR (state, val)   { state.themePrimaryColor = val   },
  UPDATE_THEME (state, val)           { state.theme             = val   },
  UPDATE_WINDOW_WIDTH (state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y (state, val) { state.scrollY           = val   },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  FLUSH_USER_DIAGNOSTIC (state) {
    state.userDiagnostic = null
    localStorage.removeItem('userDiagnostic')
    sessionStorage.removeItem('userDiagnostic')
  },

  FLUSH_USER (state) {
    state.session_id                        = null
    state.AppActiveUser                     = {}
    state.subscriptionUsage                 = {}
    state.portals                           = {}
    state.portal_id                         = ''
    // state.portal_slug                    = '' // ne pas vider cette valeur pour conserver la redirection
    state.portal_nicename                   = ''
    state.projectsLocation                  = null
    state.projectsLegend                    = null
    state.projectsCoordinatesSystem         = null
    state.betaTester                        = null
    state.rdp                               = null
    state.viewerVersion                     = null
    state.viewerBaseUrl                     = null
    state.current_user_id                   = null
    state.current_user_global_caps          = {}
    state.current_user_portal_caps          = {}
    state.current_user_avatar_increment     = 1

    localStorage.removeItem('userInfo')
    sessionStorage.removeItem('userInfo')
    localStorage.removeItem('loggedIn')
    // localStorage.removeItem('vuex')
    // sessionStorage.removeItem('vuex')
    // window.localStorage.clear()
    // window.sessionStorage.clear()
  },

  // Updates user info in state and localstorage
  UPDATE_USER_INFO (state, payload) {

    // Get Data localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

    for (const property of Object.keys(payload)) {

      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }

    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo))

    // ROYBON / Incrémente la version de l'avatar
    state.current_user_avatar_increment = state.current_user_avatar_increment + 1
  }
}

export default mutations

