<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <router-view :key="$route.fullPath" @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import jwt         from '@/http/requests/auth/jwt/index.js'

// ROYBON / Activity logger

// import axios from '@/axios'

export default {
  data () {
    return {
      vueAppClasses: []
    }
  },
  watch: {
    '$store.state.AppActiveUser' () {
      // this.getUserPortalCaps()
    },
    '$store.state.theme' (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }
  },
  methods: {
    displayDevServerAlert () {
      this.$vs.notify({ 
        title: this.$t('devServerTitle'), 
        text: this.$t('devServerText'), 
        color: 'danger', 
        position: 'top-center', 
        time: 15000, 
        iconPack: 'feather',
        icon:'icon-alert-triangle' 
      })
    },
    toggleClassInBody (className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    }
  },
  beforeMount () {
    // ROYBON / Modifie la langue en fonction du paramètre enregistré en profil
    const lang = this.$store.state.AppActiveUser.user_lang
    if (typeof lang !== 'undefined') {
      if (lang !== '') this.$i18n.locale = lang
    }
  },
  mounted () {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    if (window.location.host.includes('rimnat.dev')) {
      this.displayDevServerAlert()
    }

  },
  async created () {

    // jwt
    jwt.init()

    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

    // Auth0
    try       { 
      // Recall every 60 min to prevent passive logout
      /*
      const auth = this.$auth
      setInterval(function () { 
        auth.renewTokens() 
      }, 3600 * 1000)
      */
      await this.$auth.renewTokens() 
    } catch (e) { 
      console.error(e) 
    }

  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>
