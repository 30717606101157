/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
==========================================================================================*/

const actions = {

  // /////////////////////////////////////////////
  // RIM-Nat
  // /////////////////////////////////////////////
  // ipData
  updateIpData ({ commit }, data) {
    commit('UPDATE_IP_DATA', data)
  },
  // user configuration diagnostic
  updateUserDiagnostic ({ commit }, data) {
    commit('UPDATE_USER_DIAGNOSTIC', data)
  },
  // session_id
  updateSessionId ({ commit }, session_id) {
    commit('UPDATE_SESSION_ID', session_id)
  },
  // portals
  updatePortals ({ commit }, portals) {
    commit('UPDATE_PORTALS', portals)
  },
  // portal_id
  updatePortalId ({ commit }, portal_id) {
    commit('UPDATE_PORTAL_ID', portal_id)
  },
  // portal_slug
  updatePortalSlug ({ commit }, portal_slug) {
    commit('UPDATE_PORTAL_SLUG', portal_slug)
  },
  // portal_params
  updatePortalParams ({ commit }, portal) {
    commit('UPDATE_PORTAL_PARAMS', portal)
  },
  // portal_nicename
  updatePortalNicename ({ commit }, portal_nicename) {
    commit('UPDATE_PORTAL_NICENAME', portal_nicename)
  },
  // projects search list
  updateProjectsSearchList ({ commit }, projects_search_list) {
    commit('UPDATE_PROJECTS_SEARCH_LIST', projects_search_list)
  },
  // current_user_global_caps
  updateCurrentUserGlobalCaps ({ commit }, current_user_global_caps) {
    commit('UPDATE_CURRENT_USER_GLOBAL_CAPS', current_user_global_caps)
  },
  // current_user_portal_caps
  updateCurrentUserPortalCaps ({ commit }, current_user_portal_caps) {
    commit('UPDATE_CURRENT_USER_PORTAL_CAPS', current_user_portal_caps)
  },
  // projects parameters
  updateProjectsLocation ({ commit }, value) {
    commit('UPDATE_PROJECTS_LOCATION', value)
  },
  updateProjectsLegend ({ commit }, value) {
    commit('UPDATE_PROJECTS_LEGEND', value)
  },
  updateProjectsCoordinatesSystem ({ commit }, value) {
    commit('UPDATE_PROJECTS_COORDINATES_SYSTEM', value)
  },
  updateBetaTester ({ commit }, value) {
    commit('UPDATE_BETA_TESTER', value)
  },
  updateRdp ({ commit }, value) {
    commit('UPDATE_RDP', value)
  },
  updateViewerVersion ({ commit }, value) {
    commit('UPDATE_VIEWER_VERSION', value)
  },
  // subscription usage
  updateSubscriptionUsage ({ commit }, usageObject) {
    commit('UPDATE_SUSBSCRIPTION_USAGE', usageObject)
  },

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth ({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage ({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay ({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme ({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  flushUser ({ commit }) {
    commit('FLUSH_USER')
  },
  flushUserDiagnostic ({ commit }) {
    commit('FLUSH_USER_DIAGNOSTIC')
  },
  updateUserInfo ({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateUserRole ({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole)

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', {userRole: payload.userRole})
  }
}

export default actions
