import Vue from 'vue'
import moment from 'moment'

Vue.filter('urlify', function (value) {
  if (!value) return ''
  value = value.toString()
  value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  value = value.replace(/[^0-9a-z]/gi, '-')
  value = value.replace(/ /g, '-')
  value = value.split('--').join('-')
  value = value.substring(0, 15)
  value = value.replace(/-/gi, ' ')
  value = value.trim()
  value = value.replace(/ /gi, '-')
  value = value.toLowerCase()
  value = value.split('--').join('-')
  return value
})

Vue.filter('veryLongDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
  }
})
Vue.filter('longDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
})
Vue.filter('middleDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})
Vue.filter('shortDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YY')
  }
})
Vue.filter('hours', function (value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})

Vue.filter('prettyBytes', function (num) {
  if (!isNaN(num)) {
    if (typeof num !== 'number') {
      const parsed = parseFloat(num)
      if (isNaN(parsed)) { 
        throw new TypeError('Expected a number or string value of a number')
      } else {
        num = parsed
      }
    }
  }

  let exponent = null
  let unit = null
  const neg = num < 0
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return `${(neg ? '-' : '') + num  } B`
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1)
  num = (num / Math.pow(1024, exponent)).toFixed(2) * 1
  unit = units[exponent]

  return `${(neg ? '-' : '') + num  } ${  unit}`
})

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  const arr = value.split(' ')
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('truncate', function (value, limit) {
  return value.substring(0, limit)
})

Vue.filter('tailing', function (value, tail) {
  return value + tail
})

Vue.filter('time', function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value))
    let hours = date.getHours()
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM'
      hours = hours % 12 || 12
      return `${hours}:${min} ${time}`
    }
    return `${hours}:${min}`
  }
})

Vue.filter('date', function (value, fullDate = false) {
  value = String(value)
  const date = value.slice(8, 10).trim()
  const month = value.slice(4, 7).trim()
  const year = value.slice(11, 15)

  if (!fullDate) return `${date} ${month}`
  else return `${date} ${month} ${year}`
})

Vue.filter('month', function (val, showYear = true) {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./
  if (!showYear) {
    return regx.exec(val)[1]
  } else {
    return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`
  }

})

Vue.filter('csv', function (value) {
  return value.join(', ')
})

Vue.filter('filter_tags', function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, '')
})

Vue.filter('k_formatter', function (num) {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
})
