/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import themeConfig, { colors } from '@/../themeConfig.js'

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
const userDefaults = {
  uid         : 1,
  displayName : 'Public User',
  about       : 'This user is not connected',
  // photoURL    : require('@/assets/images/portrait/small/avatar-s-2.jpg'),
  status      : 'online',
  userRole    : '', 
  user_id     : null
}

const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {}

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  const userInfo = {}

  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key]
  })

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}


// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser             : getUserInfo(),
  bodyOverlay               : false,
  isVerticalNavMenuActive   : true,
  is_touch_device           : is_touch_device(),
  mainLayoutType            : themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList, 
  reduceButton              : themeConfig.sidebarCollapsed,
  verticalNavMenuWidth      : 'default',
  verticalNavMenuItemsMin   : false,
  scrollY                   : 0,
  starredPages              : navbarSearchAndPinList['pages'].data.filter((page) => page.is_bookmarked),
  theme                     : themeConfig.theme || 'light',
  themePrimaryColor         : colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth             : null,

  // RIM-Nat / Valeurs globales

  // session
  session_id                        : null,
  portals                           : {},
  portal_id                         : '',
  portal_slug                       : '',
  portal_nicename                   : '',
  projectsLocation                  : null,
  projectsLegend                    : null,
  projectsCoordinatesSystem         : null,
  betaTester                        : null,
  rdp                               : null,
  viewerVersion                     : null,
  viewerBaseUrl                     : null,
  current_user_id                   : null, 
  current_user_global_caps          : {},
  current_user_portal_caps          : {},
  current_user_avatar_increment     : 1,

  // layers
  layer_types_v14: [
    {
      beta: false, 
      slug: 'pointcloud',
      i18n_single: 'Pointcloud',
      i18n_multiple: 'Pointclouds',
      icon : 'icon-cloud',
      extensions : ['.zip', '.las', '.laz'],
      i18n_uploadExplainations : 'i18n_pointcloudUploadExplainations',
      i18n_uploadExplainationsDetails : null,
      i18n_uploadExplainationsAlert : 'i18n_pointcloudUploadExplainationsAlert',
      img : 'website/uploader/pointcloud.jpg'
    },
    {
      beta: false, 
      slug: 'mesh',
      i18n_single: 'Mesh',
      i18n_multiple: 'Meshes',
      icon : 'icon-triangle',
      extensions : ['.zip'],
      i18n_uploadExplainations : 'i18n_meshUploadExplainations',
      i18n_uploadExplainationsDetails : null,
      i18n_uploadExplainationsAlert : null,
      img : 'website/uploader/mesh.jpg'
    },
    {
      beta: false, 
      slug: 'bim',
      i18n_single: 'Bim',
      i18n_multiple: 'Bims',
      icon : 'icon-square',
      extensions : ['.zip', '.ifc'],
      i18n_uploadExplainations : 'i18n_bimUploadExplainations',
      i18n_uploadExplainationsDetails : null,
      i18n_uploadExplainationsAlert : null,
      img : 'website/uploader/bim.jpg'
    },
    {
      beta: false, 
      slug: 'shapefile',
      i18n_single: 'Shapefile',
      i18n_multiple: 'Shapefiles',
      icon : 'icon-circle',
      extensions : ['.zip'],
      i18n_uploadExplainations : 'i18n_shapefileUploadExplainations',
      i18n_uploadExplainationsDetails : null,
      i18n_uploadExplainationsAlert : 'i18n_shapefileUploadExplainationsAlert',
      img : 'website/uploader/shapefile.jpg'
    },
    {
      beta: false,
      slug: 'dao',
      i18n_single: 'Dao',
      i18n_multiple: 'Daos',
      icon : 'icon-edit-3',
      extensions : ['.zip', '.dxf'],
      i18n_uploadExplainations : 'i18n_daoUploadExplainations',
      i18n_uploadExplainationsDetails : 'i18n_daoUploadExplainationsDetails',
      i18n_uploadExplainationsAlert : 'i18n_daoUploadExplainationsAlert',
      img : 'website/uploader/dao.jpg'
    },
    {
      beta: false,
      slug: 'pano360',
      i18n_single: 'Pano360',
      i18n_multiple: 'Pano360',
      icon : 'icon-globe',
      extensions : ['.zip'],
      i18n_uploadExplainations : 'i18n_pano360UploadExplainations',
      i18n_uploadExplainationsDetails : null,
      i18n_uploadExplainationsAlert : 'i18n_pano360UploadExplainationsAlert',
      img : 'website/uploader/pano360.jpg'
    },
    {
      beta: false,
      slug: 'structured_pointcloud',
      i18n_single: 'StructuredPointcloud',
      i18n_multiple: 'StructuredPointclouds',
      icon : 'icon-cloud-snow',
      extensions : ['.e57', '.zip'],
      i18n_uploadExplainations : 'i18n_structuredPointcloudUploadExplainations',
      i18n_uploadExplainationsDetails : null,
      i18n_uploadExplainationsAlert : 'i18n_structuredPointcloudUploadExplainationsAlert',
      img : 'website/uploader/structured_pointcloud.jpg'
    }
  ],
  // subscription usage
  subscriptionUsage: {
    timeStart                   : null,
    timeEnd                     : null,
    timeUsedPercent             : null,
    timeDaysAvailable           : null,
    spaceUsedPercent            : null,
    spaceUsed                   : null,
    spaceTotal                  : null,
    layersTotal                 : null,
    layersUsed                  : null,
    layersAvailable             : null,
    layersUsedPercent           : null,
    projectsTotal               : null,
    projectsUsed                : null,
    projectsAvailable           : null,
    projectsUsedPercent         : null,
    usersTotal                  : null,
    usersUsed                   : null,
    usersAvailable              : null,
    usersUsedPercent            : null
  },
  tagsTypes:['text', 'web', 'photo', 'realtime'],

  // user configuration diagnostic
  userDiagnostic: null, 
  ipData: null
}

export default state
