/* eslint-disable */

export default {
  en: {
    // RIM-Nat Custom Value 

    layer_key: 'Layer key',
    layer_server: 'Host server id',
    user_email: 'User email',
    debug: 'Debug',
    previewLayer: 'Preview layer',
    viewLayerInitFile: 'Control init file',
    downloadConversionLogFile: 'Control converter log',
    downloadSourceFiles: 'Download source files',
    viewProject: 'View project',
    deleteProjectLayerText: 'This layer allocation will be deleted and this action cannot be canceled',
    dispatchLayerUpdatesText: 'Apply all the updates ?',
    dispatchUserUpdatesText: 'Apply all the updates ?',
    dispatchShareUpdatesText: 'Apply all the updates ?',
    firstName: 'First name',
    first_name: 'First name',
    lastName: 'Last name',
    last_name: 'Last name',
    user_lang: 'Language',
    user_id: 'User ID',
    ssoId: 'SSO ID',
    creationTime: 'Creation time',
    creationDate: 'Creation date',
    projectsCount: 'Projects count',
    portalsCount: 'Portals count',
    portals: 'Portals',
    hash: 'Code',
    shareHash: 'Share code',
    portal_slug: 'Portal identifier',
    projects: 'Projects',
    expire_date: 'Expire date',
    Creator: 'Creator',
    use_limit: 'Use limit',
    useLimit: 'Use limit',
    uses: 'Uses',
    users: 'Users',
    password_protected: 'Password protected',
    password: 'Password',
    deleteShareText: 'The share will no longer be usable but the users who used it will retain their rights.',

    yes: 'yes',
    no: 'no',
    
    devServerTitle: 'You are on a test server',
    devServerText: '<p> Data is a clone of production last night. </p> <p> your actions on this server will never be brought back to production. </p> <p> your actions on this server will all be deleted next night. </p>',

    betaFeaturesEnabled : 'beta features enabled',
    Diagnostic : 'Diagnostic',
    Diagnostics : 'Diagnostics',
    Browser : 'Browser',
    Summary : 'Summary',
    Full : 'Full',
    WebGL : 'WebGL',
    Device : 'Device',
    ipData : 'ipData',
    computedValues : 'Computed values',
    Analysis : 'Analysis',

    analyticsRegisteredUsers: 'Total registered users',
    analyticsHostedLayers: 'Total hosted layers',
    analyticsActiveUsers: 'Active users',
    analyticsConversions: 'Layers upload',
    by: 'by',
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
    startDate: 'Start date',
    endDate: 'End date',
    scale: 'Scale',

    diagnosticNotDone: 'The compatibility of your configuration must be checked',
    gotoDiagnostic: 'Click here to perform the diagnostic',
    diagEverythingIsOk: 'Your configuration is compatible',
    diagProblems: 'Your configuration is causing some problems',
    diagMoreInfos: "Click here for more information",
    diagNotSupportedBrowser: "Your browser is not compatible",
    diagWebGlNotSupported: "WebGL is not supported is this browser",
    diagBrowserOutdated: "Your browser must be updated",
    diagCookiesDisabled: "Your browser does not allow cookies",

    environment: 'Environment',
    os: "Operating system",
    browser: 'Browser',
    browserSupportedAndUpToDate: 'Your browser is compatible and up to date',
    gpu: 'Graphics card (GPU)',
    gpuSupported: 'Your graphics card is compatible',
    unknownGpu: 'Unknown graphics card compatibility',
    hybridGpuError: "No high performance GPU are recognized by this browser",
    hybridGpuErrorExplained: "You can access 3D models but performance will not be optimal",
    please: "Please",
    getGpuName: "Get the name of your graphics card",
    downloadAndInstall: "Download and install this file and restart your device",
    readThisDocument: "Follow the following procedure",
    useEdgeChromium: "Use the latest version of Microsoft Edge Chromium",
    checkGpuMatch: "If the value above does not match the name of your graphics card",
    downloadAndInstallEdgeChromium: "Download and install the latest version of Edge Chromium",
    comeBackWithEdgeChromium: "Come back to this page with Edge Chromium",
    updateGpuDriver: "Update your graphics card driver",
    checkupThisPage: "Visit this page",
    contactUsAt: "Contact us at",
    graphicPerformances: "Graphics performance",
    gpuScore: "GPU score",
    g3dScore: "G3D Mark score",
    unknownGpuScore: "Unknown score",
    gpuBenchmarkPrecision: "Reliability of the benchmark",
    lod: "Recommended level of detail",
    webgl: "WebGL",
    webglSupported: "WebGL is supported",
    returnWith : "Return to this page with the latest version of",
    or : "or",
    networkStatus : "Connection status",
    yourNetwork : "Your network",
    unsafeNetwork : "Unsecured connection",
    safeNetwork : "Secure connection",
    connectionSpeed : "Connection speed",
    youAreOffline : "You are offline",
    insuffisentConnection : "Insufficient connection",
    goodConnection : "Good connection",
    browserCaching : "Browser cache",
    browserCachingDisabled : "Browser caching is disabled, enable it to improve performance",
    browserCachingEnabled : "Browser caching is enabled",
    cookies : "Cookies",
    cookiesBlocked : "Cookies are blocked, the application cannot work properly",
    cookiesEnabled : "Cookies are enabled",
    copyDiagnosticToClipBoard : "Copy the diagnostic to the clipboard",
    forceDiagnosticUpdate : "Update diagnostic",
    displayFullsDiagnostic : "Display full diagnostic",


    model_language_changed : 'Viewer translation',
    model_initial_load : 'Loading the model',
    user_ip : 'User IP',
    work_loaded : 'Loaded working state',
    work_saved : 'Saved working state',
    work_save_error : 'Error while saving work',
    lod_changed : 'LOD changed',
    framerate_watcher : "Display frequency",
    lod_auto_status : "Automatic quality",
    tag_added : "Adding a tag",
    share_infos : "Obtaining sharing information",
    get_previous_work_state : "Return to the previous working state",
    get_next_work_state : "Return to the next working state",
    activeUsers: 'Active users',
    usedPortals: 'Portals used',
    activitiesSince: 'Activities since',
    seeMore: 'See more',
    seeAll: 'See all',
    warnings: 'Alerts',
    errors: 'Errors',
    activities: 'Activities',
    newActivitiesDetected: 'New activities detected',
    gpu_scanned: 'GPU benchmark',
    Result: 'Résult',
    viewer_exited: 'Viewer exited',
    Date: 'Date',
    Type: 'Type',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    liveConversions: 'Conversions',
    step: 'step',
    cpuUsage: 'CPU usage',
    memoryUsage: 'RAM usage',

    sharedPortalProjectInformations : 'Inter-portal project sharing information',
    controlKey : 'Check key',
    p404Title : '404 Not Found',
    Portal : 'Portal',
    ProjectsManager : 'Projects Manager',
    TagsManager : 'Tags manager',
    UsersManager : 'Users manager',
    Customize : 'Customize',
    Subscription : 'Subscription',
    Portals : 'Portals',
    Portal : 'Portal', 
    Projects : 'Projects',
    Project : 'Project',
    Layers : 'Layers',
    Calculation : 'Calculation',
    Calculations : 'Calculations',
    calculationsCreate : 'Launch calculation',
    calculationsLog : 'Calculations logs',
    Status : 'Status',
    Output : 'Output',
    Actions : 'Actions',
    MntDiffDescription: 'Volume calculation between two DTM',
    selectFirstLayer: 'Select first layer',
    selectSecondLayer: 'Select second layer',
    overlap: 'Recouvrement',
    noCollindingLayersFound: 'No colliding layers found',
    calculationSettings: "Calculation settings",
    quality: "Quality",
    densityUniformization: "Density uniformization",

    cutLineWidth: "Neutralized width between cut/fill",
    maxBodies: "Max number of this sub-elements",
    bodiesVolMin: "Min. volume of sub-elements (m3)",
    bodiesVolMax: "Max. volume of sub-elements (m3)",
    zAxisVectorAuto: "Automatic Z axis",

    Layer : 'Layer',
    Model : 'Model',
    Users : 'Users',
    Tags : 'Tags',
    Administration : 'Administration',
    AccessButton : 'Access',
    RequestAccessButton : 'Request access',
    SendingRequestAccessButton : "Sending access request...",
    PendingRequestAccessButton : "Access request sent",
    RejectedRequestAccessButton : "Access request rejected",
    AcceptedRequestAccessButton : "Access request accepted",
    projectAccessRequestDetails : "Access request",
    pleaseAdjustUserRights: "Please adjust the user's rights before closing the request",
    accept : "Accept",
    refuse : "Refuse",    
    pending : "pending",
    accepted : "accepted",
    refused : "refused",
    closeRequest : "Close request", 
    RequestClosed: "This request is now closed. If you need to change user rights, please go to the project page.",    
    ProjectAccessRequests: "Pending access requests",
    ProjectAccessRequestsExplainations: "You administer the users of this project: your action is required on these requests.",
    Model3D : '3D Model',
    Datas3d : '3D Datas',
    Mesh : 'Mesh',
    mesh : 'Mesh',
    Meshes : 'Meshes',
    Pointcloud : 'Pointcloud',
    pointcloud : 'Pointcloud',
    Pointclouds : 'Pointclouds',
    StructuredPointcloud : 'Structured pointclouds',
    StructuredPointclouds : 'Structured pointclouds',
    Shapefile : 'Shapefile',
    shapefile : 'Shapefile',
    Shapefiles : 'Shapefiles',
    pano360 : 'Pano 360',
    Pano360 : 'Pano 360',
    Dao : 'CAD file',
    dao : 'CAD file',
    Daos : 'CAD files',
    Bim : 'BIM Model',
    bim : 'BIM Model',
    Bims : 'BIM Models',
    Tags : 'Tags',
    Photos : 'Photos',
    AccessToModel : 'Access the model',
    AccessToModelDirect : 'Direct access',
    AccessToModelRdp : 'Server-side access',
    rdpOptimalSolutionMessage : 'Optimal solution adapted to your configuration',
    rdpNotRecommendedSolutionMessage : 'Reseired solution for your configuration',
    rdpRecommendedSolutionMessage : 'Recommended solution for your configuration',
    rdpExplaination : "Direct access offers optimal experience but requires a powerful device. Server-side access is only recommended for low devices.",
    rdpUnavailable : "Server-side access is not available for your device",
    rdpAccessTitle : "How to use access via the server",
    rdpAccessMessage : 'An .RDP file will be downloaded in a few seconds on your device. You will have to launch it (double click) and paste the following password:',
    rdpAccessLimitationsmessage : "Server access is not possible on Windows family. Type « Remote Desktop Settings » from the Start menu to check your compatibility.",
    AccessAllowed : 'Accès Allowed',
    AccessDeniedTitle : 'Access denied',
    PortalAccessDeniedFull : "Sorry, you don't have rights to access to this portal",
    PortalAccessNotYetOpened : "Sorry, your access to this portal is not yet active",
    PortalAccessExpired : "Sorry, your access to this portal has expired",
    ProjectAccessDeniedFull : "Sorry, you don't have rights to access to this project",
    ProjectAccessNotYetOpened : "Sorry, your access to this project is not yet active",
    ProjectAccessExpired : "Sorry, your access to this project has expired",   
    NoLayersInThisModel : "This model does not contain any 3D data for the moment",
    ModelAccessForbidden : "You do not have the rights required to view this model. Please contact a director of this project.",
    ProjectLocation : "Project location",    
    ModelLocationUnknown : "Unknown project location",
    PlaceSearchPlaceholder : "Address search",
    tagGroup: "Group",
    Edit : "Edit",    
    Save : "Save",
    Cancel : "Cancel",
    Finish : "Finish",
    Confirm : "Confirm",
    UpdateCanceled : "Update canceled",
    UpdateConfirmed: "Update confirmed",
    UpdateError: "Update error",
    ProjectLocationUpdated:     "Project location has been changed",
    ProjectLocationNotUpdated:  "Project location has not been changed",
    ProjectUpdateStarted : "Update possible",
    ProjectLocationUpdateStarted:  "Drag the marker to update project location",
    PressSaveOrCancel:  "Press save or cancel to apply",
    PressSaveToApply:  "Press save to apply",
    EditionFinished:  "Edition finished",
    EditionStarted:  "Edition started",
    UpdatesWillBeInstant:  "Updates will be instant",
    UpdatesSaved:  "Updates saved",
    default_visibility:  "Visible by default",
    default_pointcloud_material_pointsizetype:  "Adaptive pixel size",
    default_pointcloud_material_size:  "Pixels size",
    default_pointcloud_material_style:  "Display style",
    default_pointcloud_material_color:  "Color",
    layer_4d:  "4D planning",
    layer_4d_min:  "4D planning start date",
    layer_4d_max:  "4D planning end date",    

    shp_point: "Points",
    shp_point_size: "Point sizes",
    shp_point_style: "Point style",
    shp_point_color: "Dot color",
    shp_point_colored: "Solid color",
    shp_polygon_filled: "Filling",
    shp_polygon_fill_color: "Fill color",
    shp_polygon_outline: "Outlines",
    shp_polygon_outlined: "Outlined",
    shp_polygon_outline_color: "Outline color",
    shp_polygon_outline_width: "Outline thickness",
    shp_polygon_clamp_to_ground: "Auto drape on globe",
    shp_contains_height: "Raw 3D object display",
    shp_height_method: "3D behavior",
    shp_tag: "Annotations",
    shp_tag_explained: "Display a 3D annotation describing each entity of the file",
    coordinatesSystemRequired:  "A coordinate system must first be defined",
    coordinatesSystemRequiredExplained:  "This type of layer cannot be integrated in a project without a coordinate system",
    georeferencedProject:  "Georeferenced model",

    RemoveProjectLayer:  "Remove layer of this project",
    RemoveProjectLayers:  "Bulk remove layers",
    SelectProjectLayer:  "Select layer for bulk edition",
    ParameterUpdated:  "Parameter updated",
    ParameterNotUpdated:  "Parameter not updated",
    Style:  "Style",
    RGB:  "RGB",
    HEIGHT:  "Height",
    COLOR:  "Fixed color",
    ConfirmRemoval:  "Confirm removal",
    ConfirmedRemoval:  "Confirmed removal",
    ProjectLayerRemovalMessage:  "The layers will be removed from this project. They will remain assigned to any other projects that depend on them and will also remain availables in the layer library of this portal.",
    ProjectLayerRemovalConfirmationMessage:  "This layer remain assigned to any other projects that depend on it and remain also available in your layer library.",
    FailedRemoval:  "Failed Removal",
    NoLayersAllocatedForThisType:  "No layer of this type in this model",
    RemoveLayer4dPlanification:  "Remove 4D planification of this layer",
    RemoveLayer4dPlanificationConfirmation:  "4D planification removed",
    ProjectTags:  "Tags",
    actions:  "Actions",
    type :          "Type",
    placeholder :   "Marker",
    color :   "Color",
    description :   "Description",
    groupe :        "Group",
    value :         "Value",
    url :         "URL",
    RemoveProjectTag : "Remove tag",
    text :          "Texte",
    texte :          "Texte",
    web :           "Web",
    photo :         "Photo",
    realtime :      "Real time",
    mediaPreview :  "Media preview",
    projectTagsSeeForbidden :  "Sorry, you do not have access to the tags of this model. You must ask an administrator of this project to give you access",
    ProjectTagRemovalMessage:  "This tag will be permanently deleted without possible cancellation",
    ProjectTagRemovalConfirmationMessage:  "Tag has been deleted",
    ProjectUsers : "Users of this project",
    User : "User",
    userFirstName : "First name",
    userLastName : "Last name",
    userId : "ID",
    userEmail : "E-mail",
    Capabilities : "Capabilities",
    ViewCapabilities : "Visualization",
    ContributionCapabilities : "Contribution",
    ManagementCapabilities : "Administration",
    start_access: "Start of access",
    end_access: "End of access",
    projects_total: "Number of Max projects",
    layers_total: "Layers number max",
    users_total: "users number max",
    space_total: "Disk space max (Go)",
    edit: 'Edit',
    can_project_access: "Access the project",
    can_project_access_start_date: "Access start",
    can_project_access_end_date: "Access end",
    can_project_customize: "Configure project",
    can_project_users_manage: "Manage project users",
    can_project_layers_manage: "Manage model layers",
    can_project_tags_manage: "Manage project tags",
    can_project_tags_see: "See tags",
    can_project_users_see: "See project users",
    can_project_chat_read: "xxxxxxx",
    can_project_chat_write: "xxxxxxx",
    can_project_chat_moderate: "xxxxxxx",
    can_project_model_see: "See 3D model",
    can_project_tools_use: "Use 3D tools",
    can_project_tags_add: "Add tags",
    can_project_shares_see: "See shares",
    can_project_shares_send: "Share the project",
    can_project_shares_manage: "Manage shares",
    RemoveProjectUser: "Remove user",
    RemoveProjectCurrentUserImpossible: "Unable to remove yourself",
    CanRemoveCapabilityYourself: "You cannot remove this capability yourself",
    from: "from",
    to: "to",
    ProjectUserRemovalMessage:  "This user's access to this project will be removed without possible cancellation",
    ProjectUserRemovalConfirmationMessage:  "User removed of this project",
    UploadSuccess:  "Upload success",
    UploadError:  "Upload error",
    choseImage:  "Chose an image",
    choseJpgImage:  "Chose a .jpg format image",
    choseImageToChangeProjectCover:  "Chose a file if you want to change the cover image. Do nothing to keep current image.",
    treatmentInProgress:  "Treatment in progress...",
    NoResultsFound:  "No results found",
    projectNicenameEditMessage: "Change project title",
    projectSlugEditMessage: "This unique number is used in the URL. By modifying it, you will break any shortcuts created by other users.",
    projectSlugUnavailable: "This value is not available, please enter another.",
    projectSlugAvailable: "Value available",
    youCanSaveThisSlug: "You can save your parameters",
    sameValueAsCurrent: "The value is the same as current",
    noUpdateWillBeMade: "No update will be made",
    projectsList: "List",
    projectsMap: "Map",
    projectsWithoutPositionAlertTitle: "projects have not been geo-positioned and therefore do not appear on the map. Click here to see which ones.",
    noProjectPositioned: "None of your projects have yet been geo-positioned. Please add them a location.",
    noProjectInPortal: "No projects have been created in this portal yet.",
    noAuthorizedProjectInPortal: "You have no access to any project from this portal at this time.",
    noTagsInThisProject: "No tag has been added to the 3D model of this project yet.",
    gotoModelToAddTags: "Go to the 3D model to add first tags.",
    askForRightsToAddTags: "Please ask a project administrator to grant you the right to add tags.",
    addLayer: "Add layers",
    unusedLayers: "Not allocated",
    usedLayers: "Allocated",
    noUnusedLayersToShow: "No unused layers to show, but you can add any previously allocated layer.",
    layerAlreadyAllocated: "Layer already allocated to this project",
    layerCannotBeAllocatedTwice: "Same layer cannot be allocated twice in one project",
    ConfirmLayerAddition: "Confirm layer addition",
    ProjectLayerAdditionMessage: "this layer will become visible for each user of this project",
    ConfirmedAllocation: "Layer allocation confimed",
    ProjectLayerAllocationConfirmationMessage: "This layer is now visible for each user of this project",
    FailedAllocation: "Error during layer allocation",
    legend: "Legend",
    coordinatesSystem: "Coordinates system",
    epsgName: "Name",
    epsgCode: "EPSG code",
    noCoordinatesSystemFound: "No results",
    enterCoordinatesSystemQuery: "Enter a request",
    currentCoordinatesSystem: "Current system",
    selectedValueSameAsCurrent: "Selected value same as current",
    noChangeWillBeMade: "No change will be made",
    updateCoordinatesSystemConfirmationMessage: "Project's coordinates system will be changed to",
    updateCoordinatesSystemConfirmedMessage: "Project's coordinates changed",
    noCoordinatesSystem: "No coordinate system defined for the project",
    coordinatesSystemEnablesGlobe: "Specifying the project coordinate system allows you to integrate the model on a 3D globe and activate the geospatial features.",
    removeCoordinatesSystemConfirmationMessage: "Project's coordinates system will be removed",
    noLegendImg: "No legend image added",    
    removeProjectLegend: "Remove legend",
    legendRemoveConfirmMessage: "Legend will be removed",
    NewProject: "New project",
    ConfirmProjectCreation: "Create a new project",
    newProjectFormTitle: "New project creation",
    newProjectSlug : "Unique identifier for URL",
    newProjectNicename: "Project title",
    newProjectBtn: "Create the project",
    projectCreationSuccess: "Project creation success",
    projectCreationError: "Project creation error",
    removeProject: "Remove this project",
    cloneProject: "Clone this project",
    cloneProjectMessage: "A new project will be created based on this one.",
    cloneProjectChoice: "What do you want to duplicate ?",
    cloneProjectLayers: "Layers",
    cloneProjectUsers: "Users",
    cloneProjectTags: "Tags",
    cloneProjectSharedScene: "Shared scene",
    cloneProjectConfirm: "Do you want to clone this project ?",
    cloneProjectSuccess: "Project duplication success",
    cloneProjectError: "Project duplication error",
    removeProjectTypeMessage: "Enter the following value to confirm",
    projectRemoveConfirmationMessage: "This project will be removed from the portal. No more users will be able to access it. The deletion is irreversible. The 3D data assigned to the project will remain usable and assignable for other projects.",
    valueIsNotValid: "Value is not valid",
    projectDeletionCanceled: "Project deletion canceled",
    projectDeletionSuccess: "Project deletion success",
    projectDeletionError:   "Project deletion error",
    multiPortalProjectTitle:   "Multi-portals shared project",
    multiPortalProjectMessage:   "Data, tags and actions are shared between the users of :",
    removeMultiPortalProject: "Remove this shared project from this portal",
    projectMultiPortalDeletionCanceled: "Withdrawal of shared project canceled",
    projectMultiPortalDeletionSuccess: "Withdrawal of shared project from successful project",
    projectMultiPortalDeletionError: "Error removing shared project",
    projectMultiPortalRemoveConfirmationMessage: "The shared project will be removed from this portal. Your portal users will no longer be able to access it. However, it will remain accessible to people from other portals.",
    projectAddToOtherPortal: "Add this project to another portal",
    projectAddToOtherPortalTypeMessage: "Enter the information provided by the administrator of the destination portal.",
    projectAddToOtherPortalCanceled: "Project's sharing to another portal canceled",
    projectAddToOtherPortalConfirmationMessage: "This project will become a common space for several portals. The capacities for adding / removing users and / or 3D data and / or tags will apply globally for all portals.",    
    projectAddToOtherPortalConfirmationMessage2: "Irreversible action. You will not be able to delete this project from the destination portal. Only an administrator of this portal will be able to do it, without you being able to force it. ",
    readThisVeryCarefully: "Read this very carefully",
    Verification: "Verification",
    projectSharePortalSlug: "Destination portal identifier",
    projectSharePortalUserId:       "Destination portal administrator ID",
    projectAddToOtherKey:         "Security key communicated by the administrator",
    invalidKey: "Invalid key",
    validKey: "Valid key",
    projectAddToOtherPortalSuccess: "Addition to third-party portal success",
    projectAddToOtherPortalError: "Addition to third-party portal fail",
    projectAddUserSuccess: "User addition success",
    projectAddUserError: "User addition fail",
    projectUserExists: "User already has access to the project",
    invalidPortalSlug: "Invalid portal identifier",
    addUser: "Add a user",
    addUserConfirmationMessage:"Add",
    changeUserCapabilities:"Now, adjust user capabilities in users list",
    layer_id:"ID",
    layer_name:"Title",
    layer_type:"Type",
    layer_origin:"Origin",
    layerProperties:"Properties",
    layer_movable:"Movable",
    layer_deformable:"Deformable",

    layerMovableDeformable: "The position and dimensions of the object can be modified.",
    layerMovableNotDeformable: "The position of the object can be modified, but not its dimensions.",
    layerNotMovableDeformable: "The position of the object is fixed, but its dimensions can be modified.",
    layerNotMovableNotDeformable: "The object is frozen. Neither his position nor its dimensions can be changed.",

    layer_origin_x:"X",
    layer_origin_y:"Y",
    layer_origin_z:"Z",
    resetOrigin:"Get from file",
    layer_size:"Size",
    conversion_status:"Conversion",
    allocations_count:"Allocations",
    conversion_end_date:"Date",
    layerEdition:"Edit layer",
    layerInformations:"Informations",
    conversionInformations:"Conversion informations",
    layerOriginInformation:"Coordinates of the origin",
    upload_date:"Upload date",
    conversion_start_date:"Conversion start date",
    conversion_end_date:"Conversion end date",
    uploaded_filename:"File",
    sender:"Sender",
    projectsAllocations:"Projects allocations",
    noInformationsFound:"No informations found",
    noAllocation:"No allocation yet",
    layerNameEditMessage:"Layer name will be changed for all projects on all portal that use it",
    cannotManageLayers:"You don't have rights to manage the layers on this portal",
    cannotDeleteAllocatedLayer:"You cannot delete this layer while it is allocated to certain projects",
    removeLayersAllocations:"Please remove the layer from each project listed above first",
    deleteLayer:"Delete layer",
    deleteSelectedLayers:"Delete selected layers",
    deleteLayerConfirmMessage:"This layer will be deleted without possible cancellation",
    deletedLayer:"Layer deleted",
    notDeletedLayer:"Layer is not deleted",
    layerDeletionSuccess:"Deletion success",
    layerDeletionError:"Deletion error",
    subscriptionUsage:"Subscription usage",
    spaceUsage:"Disk space",
    layersUsage:"Layers",
    projectsUsage:"Projects",
    usersUsage:"Users",
    cannotRemoveProjectLayerAllocation:"Sorry but you don't have capabilities to remove this layer on this project. Please contact any project manager to allow you this action.",
    total:"Total",
    available:"Available",
    used:"Used",
    Action:"Action",
    userInformations:"Profil",
    userEdition:"User edition",
    userSelfEdition:"Edit your profile",
    userAccessibleProjects:"Member of projects",
    deleteUser:"Delete user",
    deleteUserConfirmMessage:"The user will no longer be able to connect to this RIM-Nat portal or access the projects in this portal. This deletion will not affect his rights on any other portal he accesses.",
    deletedUser:"User deleted from this portal",
    notDeletedUser:"No changes made for user",
    userDeletionSuccess:"Deletion success",
    userDeletionError:"Deletion error",
    profileUpdated:"Profile updated",
    profileNotUpdated:"Profile not updated",
    userCreation:"Add a new user",
    userCreationSubmit:"Add user",
    validEmail:"Valid email",
    invalidEmail:"Invalid email",
    account_exists:"This user already has another RIM-Nat (inactive or in another portal) account. He will be added to this portal with his current identity.",
    accountWillBeCreated:"This email address is not associated with any existing RIM-Nat account. The user will have to create his account on the RIM-Nat home page.",
    accountCreationConfirmationMessage:"The new account created will be able to access this portal but no project. You will then need to define its rights for each project.",
    subscriptionUsersExceeded:"The maximum number of users planned for the subscription has been reached",
    subscriptionProjectsExceeded:"The maximum number of projects planned for the subscription has been reached",
    subscriptionLayersExceeded:"The maximum number of layers planned for the subscription has been reached",
    userCreationSuccess:"User account created",
    userCreationError:"User account creation error",
    userPortalAllocationSuccess:"User added to portal",
    userPortalAllocationError:"User allocation error",
    user_exists_on_this_portal:"This user already has access to this portal. You can search it on this page.",
    cannotManageUsers:"You don't have rights to manage the users on this portal",
    cannotManagePortal:"You don't have rights to manage this portal",
    currentUsage:"Current subscription usage",
    timeUsage:"Subscription duration",
    days:"days",
    timeStart:"Start",
    timeEnd:"End",
    subscriptionExpired:"Subscription expired",
    changePlan:"Change plan",
    pleaseContactUs:"Please contact us",
    portalSettings:"Portal settings",
    portalNicename:"Full name of portal",
    portalSlug:"URL identifier",
    portalSlugAlertMessage:"Please note, changing this value will break all shortcuts potentially created by users. No redirection will take place. Currently connected users will have to reopen RIM-Nat at the new address.",
    danger:"Critical danger",
    error:"Error",
    portalSlugNotAvailable:"This value is not valid or not available",   
    portalNicenameUpdateConfirm:"Dou you really want to change the title of this portal ?",
    portalSlugChanged:"Portal slug changed. Please ask your users to change their shortcuts.",
    portalNicenameChanged:"Portal title changed.",
    projectsSettings:"Projects settings",
    projectsLocation:       "Activate the geolocation functions ",
    projectsLegend:             "Enable adding legend to project ",
    projectsCoordinatesSystem:  "Activate the definition of the coordinate system",
    betaTester:  "Beta functions enabled",
    rdp:  "Access to server-side rendering",
    viewerVersion:  "Viewer version",
    projectsAvailableOnPlan: "available projects on plan",
    youSeeThisProjectBecauseYouAreAdmin: "You can see this project because you have administration rights on this portal.",
    loginRequired: "Login required",    
    loginRequiredMessage: "Login is required to access this application.",
    Logout: "Logout",
    loginOrRegister: "Login or register",   
    migrationLoginMessage: "Warning: if you were using the old version of RIM-Nat and you are seeing this page for the first time: please choose \"Registration\" in the next step and create a new account with your usual email address for your data to be reconciled.",   
    privatePortal: "Private portal",
    privatePortalMessage: "This portal is private", 
    portalCaps: "Portal capabilities on",
    beta_tester: "Can access to beta functions",
    projects_location: "Can ddd projects location on map",
    projects_legend: "Can add a legend image for a each project",
    projects_coordinates_system: "Can specify coordinates system and access to 3D globe",
    can_portal_access:     "Authorized",
    can_portal_manage:     "Subscription management",
    can_portal_customize:     "Customize",
    can_portal_access_start_date:     "Access start",
    can_portal_access_end_date:       "Access end",
    can_portal_projects_see:          "Projects visibility",
    can_portal_projects_manage:       "Projects administrator",
    can_portal_layers_manage:         "Layers administrator",
    can_portal_users_manage:          "Users administrator",
    can_portal_projects_see_alert_on:           "The user sees the list of all projects in the portal, but can only access those of which he is a member.",
    can_portal_projects_see_alert_off:          "The user only sees the projects of which he is a member.",
    can_portal_projects_manage_alert:           "The user accesses all of the portal projects. He can modify them, delete them and add new ones.",
    can_portal_layers_manage_alert:             "The user can add, modify and delete layers to this portal.",
    can_portal_users_manage_alert:              "The user can add, modify and delete users to this portal, including yourself.",
    canAccessToAllProjects:   "The user accesses all the projects on this portal.",
    memberOfProject:   "Member of :",
    userActivity:           "Last activities",    
    employee:     "Employee",
    guest:        "Guest",
    capsUpdated:            "Capabilities updated",
    capsNotUpdated:         "Capabilities not updated",
    date: "Date",
    event: "Event",
    details: "Details",
    failedConversions: "Failed conversions",
    finishedConversions: "Finished conversions",
    aliveConversions: "Conversions in progress",
    data_title: "Title",
    data_type: "Type",
    progression: "Progression",
    newJobAdded: "new job(s) added to queue",
    jobFinished: "job(s) finished",
    pendingConversion: "Conversion in queue",
    noAliveConversion: "No conversion in queue or in progress. Fresh uploads can take several minutes before being visible here, please be patient.",
    layersManager: "Layers manager",
    layersUploader: "3D data uploader",
    i18n_pointcloudUploadExplainations:   "Point cloud in LAS or LAZ format, whether or not contained in a ZIP file.",
    i18n_pointcloudUploadExplainationsAlert:   "Remember to check your point cloud in software such as CloudCompare (free) before sending.",
    i18n_meshUploadExplainations:         "ZIP file containing: OBJ+MTL+JPG, or OBJ+MTL+PNG, or PLY+JPG, or PLY+PNG.",
    i18n_bimUploadExplainations:          "BIM model in IFC2x3 format, whether or not contained in a ZIP file.",
    i18n_shapefileUploadExplainations:    "ZIP file containing entire shapefile collection : SHP+SHX+DBF+[...]",
    i18n_shapefileUploadExplainationsAlert:    "Shapefile must imperatively be placed in the same projection system as the project before sending. Remember to check with software such as QGIS (free).",
    i18n_pano360UploadExplainations:          "Zip file containing all images in JPG format + the TXT format file",
    i18n_pano360UploadExplainationsAlert:     "Be careful with the timing of the timing file. Columns : File, Time, x, y, z, course, pitch, roll.",
    i18n_structuredPointcloudUploadExplainations:          "Structured E57 file containing scan stations as well and photo bubbles, whether or not contained in a ZIP file.",
    i18n_structuredPointcloudUploadExplainationsAlert:     "Remember to check the structure of the E57: each station must contain a pointcloud + a 360° panoramic",
    i18n_daoUploadExplainations:          "DXF file, whether or not contained in a ZIP file.",
    i18n_daoUploadExplainationsDetails:   "DXF Limits: 250 MB before compression / 30 Layers / 500 Objects by Layer. Conserved Entities: 2D / 3D Polylines of Non-Null Altitude 2D / 3D",
    i18n_daoUploadExplainationsAlert:     'Layers "0" and "Defpoints" are removed. The objects must be in color "ByLater". To greatly accelerate the conversion time: Remove the invalid objects, purge the drawing and delete unused layers.',
    dxfLayersManager:          "Layer management",

    dataExplainations:          "Expected files",
    emailNotAvailable:          "This email adress is not available",
    choseData:  "Drop your file here",
    nameYourData:  "Please name your dataset.",
    Ok:  "Ok",
    freshUpload:  "Your recent upload will appear here soon. Please wait a few minutes.",
    uploadSuccess:  "File received! It will soon appear in the list of current conversions. Thank you for your patience.",
    choosePortal: "Please choose your portal",
    emailNotVerifiedTitle: "Validate your email address",
    emailNotVerifiedMessage: "Please confirm your identity by clicking on the link in the email just sent to you at",
    emailNotVerifiedMessage2: "Remember to check your folder or your Spam / Junk mail filter",
    emailNotVerifiedMessage3: "Then click on the login button below",
    notAuthorizedTitle: "Not authorized",
    notAuthorizedMessage: "Your account does not allow you to access the application.",
    backToHome: "Back to home",
    userWithoutPortalMessage: "Your RIM-Nat account is not currently assigned to any portal. No problem, just ask the person who told you about RIM-Nat to add you as a user via your email address",
    userWithoutPortalTitle: "You do not have access to any RIM-Nat portal at the moment",
    welcome: "Welcome",
    youKnowAnAdministratorTitle:            "Do you know a RIM-Nat user",
    youKnowAnAdministratorMessage:          "Contact him so that he (or his administrator) can add you to their portal and to projects that concern you.",
    youHaveAnInviteTitle:                   "You received an invitation ?",
    youHaveAnInviteMessage:                 "Enter your code to update your capabilities.",
    howToGetAccess:                         "How to get access ?",

    app_login:                              "App login",
    app_logout:                             "App logout",
    portal_projects:                        "Portal projects list access",
    portal_project_creation:                "Project creation",
    delete_project_layer:                   "Layer removal on project",
    delete_portal_layer:                    "Layer deletion on portal",
    layer_edition:                          "Layer edition",
    portal_access:                          "Portal access",
    user_creation:                          "User creation",
    user_portal_allocation:                 "User portal allocation",
    portal_users:                           "Portal users page access",
    project_clone:                          "Project duplication",
    project_portal_removal:                 "Project removal",
    project_multiportal_share:              "Project share with other portal",
    project_multiportal_import:             "Project import from another portal",
    project_user_allocation:                "Project user allocation",
    project_legend_update:                  "Project legend update",
    project_coordinates_system_update:      "Project coordinates system update",
    project_coordinates_system_removal:     "Project coordinates system removal",
    project_slug_update:                    "Project slug update",
    project_nicename_update:                "Project nicename update",
    project_cover_update:                   "Project cover update",
    project_location_update:                "Project location update",
    project_user_arrival:                   "Project user arrival",
    project_layer_allocation:               "Project layer allocation",
    project_layer_removal:                  "Project layer removal", 
    project_layer_4d_removal:               "Project layer 4D planification removal", 
    project_layer_parameter_update:         "Project layer parameter update", 
    project_user_cap_update:                "Project user capabilitie update", 
    project_user_cap_updated:               "Project capabilities updated by administrator", 
    project_user_remove:                    "Project user removal", 
    project_user_removed:                   "Project access removed by administrator", 
    project_tag_remove:                     "Project tag removal", 
    project_tag_update:                     "Project tag update", 
    project_access:                         "Project access", 
    project_access_refused:                 "Project access refused", 
    data_upload_start:                      "Dataset upload started", 
    data_uploaded:                          "Dataset uploaded to converter", 
    layer_manager_access:                   "Layers manager access", 
    layer_manager_access_refused:           "Layers manager access refused", 
    layer_sidebar_open:                     "Layer edition sidebar opened", 
    layer_update:                           "Layer update", 
    profile_update:                         "User profile update", 
    profile_updated:                        "User profile updated", 
    profile_portal_caps_update:             "User portal capabilities update", 
    profile_portal_caps_updated:            "User portal capabilities updated by administrator", 
    portal_user_removal:                    "Portal user removal", 
    portal_user_removed:                    "User removed from portal by administrator", 
    project_user_removal:                   "Project user removal", 
    project_user_removed:                   "User removed from project by administrator", 
    portal_info_update:                     "Portal informations update",
    avatar_upload:                          "Avatar update",    
    portal_projects_parameters_update:      "Portal projects parameters update",
    portal_slug_update:                     "Portal URL update",
    portal_nicename_update:                 "Portal nicename update",
    share_manager_access:                   "Share page access",
    share_manager_access_refused:           "Share page access refused",
    share_creation:                         "Share creation",
    share_used:                             "Share used",
    project_keyword_add:                    "Keyword added to project",
    project_keyword_delete:                 "Keyword removed from project",
    project_access_request_closed:          "Access request closed",
    project_access_request_refusal:         "Refusal of a refused access request",
    project_access_request_refused:         "Access request denied",
    project_access_request_acceptation:     "Acceptance of an access request",
    project_access_request_accepted:        "Access request accepted",
    project_access_request_manager:         "Access to the access request manager",
    project_access_request_created:         "Access request to a project sent",
    project_access_request_creation_error:  "Error when requesting access to a project",
    model_screenshot:                       "Model screenshot",
    user_agent:                             "User agent",
    diagnostic_page_access:                 "Accès au diagnostic",

    activityDetails:            "Activity details",
    success:                    "Success",
    Shares:                       "Shares",
    thisProjectWillBeVisibleInList: "This project will be added in visibles projects list of the share's users. Please now define capabilities.",
    projectsChoice: "Projects you can share",
    projectsCaps: "Project.s capabilities",
    Step: "Step",
    Validation: "Validation",
    shareCreation:  "New share creation",
    shareManager:   "Manager shares",
    noShares:   "No shares to display for the moment",
    Next:       "Next",
    Back:   "Back",
    TextCopySuccess:   "Text copied successfully",
    TextCopyError:      "Error in copying text",
    Copy:               "Copy",
    CopyPassword:               "Copy password",
    Limitations:               "Limitations",
    limitationsExplaination:   "Add some optional limits to enhance the security of this sharing.",
    shareLimitUsageNumber:     "Max number of users",
    passwordProtection:     "Password protection",
    expireDate:     "Expire date",
    shareLimitations:     "Share token limitations",
    shareCode:     "Share code",
    shareLink:     "Share link",
    shareLimitUsageNumberExplainations: "Limits the number of distinct users who can use the code or the sharing link. Uses by the same user are not deducted.",
    shareLimitPasswordExplainations:    "Password of your choice which will be requested from the user. Make sure that you never publish the share link and the password in the same document.",
    shareLimitDateExplainations:        "Share link expiration date. This date is not linked to any other dates defined by project in the previous step.",
    shareCodeZone:                "Here is your share link!",
    shareCodeZoneExplainations:   "You can communicate it by email or integrate it as a link to any document. You can also download the QR Code and ask your recipient to scan it.",
    qrCode:   "QR Code",
    createNewShare:   "Create new share",
    shareCreationReady:             "Your sharing is ready",
    shareCreationClickToConfirm:    "Please click Next to finalize its creation and allow its distribution",    
    shareCreationSuccess:           "Share link created successfully",
    shareCreationSuccessText:       "You can now communicate it",
    shareCreationError:             "Error on share link creation",
    shareWelcomeMessage:            "RIM-Nat allows you to collaborate securely on your projects.",
    shareStartButton:               "Create new share",
    previousShares:                 "Shares history",
    createdBy:                 "Created by",
    noLimitations:                 "No limitations",
    Validity:             "Validity",
    shareLimitUsageExceeded:  "Max number of users exceeded",
    shareExpireDateExceeded:  "Expire date exceeded",
    noUsers:  "No users",
    removeShare:  "Remove share",
    removeShareConfirmWithUsers:  "Warning: Users who have used this share will lose all their rights on the projects concerned. In addition, this sharing will become permanently unusable to any new user.",
    removeShareConfirmWithoutUsers:  "Warning: This share will become permanently unusable.",    
    shareRemovalSuccess:  "Share removal success",
    shareRemovalError:    "Share removal error",
    shareDetails:         "Settings",
    shareInformations:    "Share links",
    valid: "Valid",
    invalid: "Invalid",
    usable: "Usable",
    unusable: "Unusable",
    locked: "Locked",
    invitationFrom : "Invitation from",
    expireDatePast : "Sharing expiration date exceeded",
    numberOfUsersExceeded : "Maximum usage exceeded",
    passwordInvalid : "Invalid password",
    passwordProtectedShare : "Password protected share",
    typePasswordHere: "Type password here",
    wrongPassword: "Wrong password",
    shareUseNeedsConnection: "You must be logged in to use this share. If you don't have an account, you can create one for free.",
    rightsWillBeAdded: "This sharing allows you to access to following projects with the following capabilities",
    acceptInvitation: "Accept invitation",
    cannotUseYourOwnShare: "You cannot use this share because you are the creator.",
    shareUsedTitle: "Invitation accepted",
    shareUsedText: "Your capabilities have been updated",
    shareUnusable: "This share is no longer usable, you can no longer share it.",
    language: "Language",
    en: "English",
    fr: "French",
    publicGalleryTitle: "Public gallery",
    publicGalleryMessage: "Here are some projects made public by",
    notLoggedinMessage: "You are not logged in.",
    publicModel: "Public model",
    userAccessibleModel: "You are a member of this project",
    adminAccessibleModel: "You access as administrator",
    userNotAccessibleModel: "You don't have access to this model",
    setProjectStatusPrivate: "Disable public status",
    setProjectStatusPublic: "Make public",
    setProjectStatusPublicConfirm: "Please note, your model will become accessible to any RIM-Nat user outside your portal. This function is useful for promoting your expertise but is not recommended from a data confidentiality point of view.",
    mostRecentFirst: "Most recent",
    alphabeticalSort: "Alphabetical",
    publicFirst: "Public",
    sortBy: "Sort by",
    newKeyword: "New keyword",
    keyword: "keyword",
    All: "All",
    someProjectsWithoutPosition: "Some projects have not been geolocated and are therefore not visible on the map.",
    emptyFilteredProjectsList: "No project matching your filters",

    // DEFAULT THEME VALUES 
    Dashboard: 'Dashboard',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'RIM-Nat',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analytics',
    Activity: 'Activity',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    // "Import/Export": 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'Others',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.'
  },

  fr: {
    // RIM-Nat Custom Value 
    layer_key: 'Clé de la couche',
    layer_server: 'ID du serveur hôte',
    user_email: 'Email utilisateur',
    debug: 'Débug',
    previewLayer: 'Prévisualiser',
    viewLayerInitFile: "Contrôle du fichier d'initialisation",
    downloadConversionLogFile: "Contrôle de l'historique de conversion",
    downloadSourceFiles: 'Télécharger les fichiers source',
    viewProject: 'Voir le projet',
    deleteProjectLayerText: "Cette couche ne sera plus affecté à ce projet",
    dispatchLayerUpdatesText:   'Appliquer les modifications ?',
    dispatchUserUpdatesText:    'Appliquer les modifications ?',
    dispatchShareUpdatesText:   'Appliquer les modifications ?',
    firstName: 'Prénom',
    first_name: 'Prénom',
    lastName: 'Nom',
    last_name: 'Nom',
    user_lang: 'Langage',
    user_id: 'User ID',
    ssoId: 'SSO ID',
    creationTime: 'Date de création',
    creationDate: 'Date de création',
    projectsCount: 'Projets',
    portalsCount: 'Portails',
    portals: 'Portails',
    hash: 'Code',
    shareHash: 'Code de partage',
    portal_slug: 'Identifiant du portail',
    projects: 'Projets',
    expire_date: "Date d'expiration",
    Creator: 'Créateur',
    use_limit: "Limite d'utilisations",
    useLimit: "Limite d'utilisations",
    uses: 'Utilisations',
    users: 'Utilisateurs',
    password_protected: 'Protection par mot de passe',
    password: 'Mot de passe',
    deleteShareText: "Ce partage ne sera plus utilisable mais les personnes l'ayant utilisé par le passé conserveront leurs droits",

    yes: 'oui',
    no: 'non',

    devServerTitle: 'Vous êtes sur un serveur de test',
    devServerText: '<p>Les données sont un clone de la production de la nuit dernière.</p><p>Vos actions sur ce serveur ne seront jamais remontées vers la production.</p><p>Vos actions sur ce serveur seront toutes supprimées la nuit prochaine.</p>',
  
    betaFeaturesEnabled : 'fonctions beta activées',
    Diagnostic : 'Diagnostic',
    Diagnostics : 'Diagnostics',
    Browser : 'Navigateur',
    Summary : 'Résumé',
    Full : 'Complet',
    WebGL : 'WebGL',
    Device : 'Appareil',
    ipData : 'ipData',
    computedValues : 'Valeurs calculées',
    Analysis : 'Analyse',
    
    analyticsRegisteredUsers: 'Total des utilisateurs inscrits',
    analyticsHostedLayers: 'Total des couches hébergées',
    analyticsActiveUsers: 'Utilisateurs actifs',
    analyticsConversions: 'Couches envoyées',
    by: 'par',
    day: 'jour',
    week: 'semaine',
    month: 'mois',
    year: 'année',
    startDate: 'Début',
    endDate: 'Fin',
    scale: 'Echelle',

    diagnosticNotDone: 'La compatibilité de votre configuration doit être vérifiée',
    gotoDiagnostic: 'Cliquez ici pour effectuer le diagnostic',
    diagEverythingIsOk: 'Votre configuration est compatible',
    diagProblems: 'Votre configuration pose certains problèmes',
    diagMoreInfos: "Cliquez ici pour plus d'informations",
    diagNotSupportedBrowser: "Votre navigateur n'est pas compatible",
    diagWebGlNotSupported: "WebGL n'est pas pris en charge dans ce navigateur",
    diagBrowserOutdated: "Votre navigateur doit être mis à jour",
    diagCookiesDisabled: "Votre navigateur n'autorise pas les cookies",

    environment: 'Environnement',
    os: "Système d'exploitation",
    browser: 'Navigateur',
    browserSupportedAndUpToDate: 'Votre navigateur est compatible et à jour',
    gpu: 'Carte graphique (GPU)',
    gpuSupported: 'Votre carte graphique est compatible',
    unknownGpu: 'Compatibilité de la carte graphique inconnue',
    hybridGpuError: "Aucun GPU hautes performances n'est reconnu par ce navigateur",
    hybridGpuErrorExplained: "Vous pouvez accéder aux modèles 3D mais les performances ne seront pas optimales",
    please: "Veuillez",
    getGpuName: "Obtenir le nom de votre carte graphique",
    downloadAndInstall: "Télécharger et installer ce fichier puis redémarrer votre appareil",
    readThisDocument: "Appliquer la procédure suivante",
    useEdgeChromium: "Utiliser la dernière version de Microsoft Edge Chromium",
    checkGpuMatch: "Si la valeur ci-dessus ne correspond pas au nom de votre carte graphique",
    downloadAndInstallEdgeChromium: "Téléchargez et installez la dernière version d'Edge Chromium",
    comeBackWithEdgeChromium: "Revenez sur cette page avec Edge Chromium",
    updateGpuDriver: "Mettez à jour le pilote de votre carte graphique",
    checkupThisPage: "Consultez cette page",
    contactUsAt: "Contactez-nous à",
    graphicPerformances: "Performances graphiques",
    gpuScore: "Score GPU",
    g3dScore: "Score G3D Mark",
    unknownGpuScore: "Score inconnu",
    gpuBenchmarkPrecision: "Fiabilité de l'évaluation",
    lod: "Niveau de détails recommandé",
    webgl: "WebGL",
    webglSupported: "WebGL est supporté",
    returnWith : "Revenir sur cette page avec la dernière version de",
    or : "ou",
    networkStatus : "Etat de la connexion",
    yourNetwork : "Votre réseau",
    unsafeNetwork : "Connexion non sécurisée",
    safeNetwork : "Connexion sécurisée",
    connectionSpeed : "Vitesse de connexion",
    youAreOffline : "Vous êtes hors ligne",
    insuffisentConnection : "Connexion insuffisante",
    goodConnection : "Bonne connexion",
    browserCaching : "Cache navigateur",
    browserCachingDisabled : "La mise en cache navigateur est désactivée, activez-la pour améliorer les performances",
    browserCachingEnabled : "La mise en cache navigateur est activée",
    cookies : "Cookies",
    cookiesBlocked : "Les cookies sont bloqués, l'application ne peut pas fonctionner correctement",
    cookiesEnabled : "Les cookies sont activés",
    copyDiagnosticToClipBoard : "Copier le diagnostic dans le presse-papiers",
    forceDiagnosticUpdate : "Mettre à jour le diagnostic",
    displayFullsDiagnostic : "Afficher le diagnostic intégral",
    
    model_language_changed : 'Traduction du viewer',
    model_initial_load : 'Chargement du modèle',
    user_ip : 'IP utilisateur',
    work_loaded : 'Etat de travail chargé',
    work_saved : 'Etat de travail sauvegardé',
    work_save_error : 'Erreur lors de la sauvegarde du travail',
    lod_changed : 'LOD changé',
    framerate_watcher : "Fréquence d'affichage",
    lod_auto_status : "Qualité automatique",
    tag_added : "Ajout d'un tag",
    share_infos : "Obtention des informations de partage",
    get_previous_work_state : "Retour à l'état de travail précédent",
    get_next_work_state : "Retour à l'état de travail suivant",
    activeUsers: 'Utilisateurs actifs',
    usedPortals: 'Portails utilisés',
    activitiesSince: 'Activités depuis',
    seeMore: 'Voir plus',
    seeAll: 'Voir tout',
    warnings: 'Alertes',
    errors: 'Erreurs',
    activities: 'Activités',
    newActivitiesDetected: 'Nouvelles activités détectées',
    gpu_scanned: 'GPU évalué',
    Result: 'Résultat',
    viewer_exited: 'Sortie du viewer',
    Date: 'Date',
    Type: 'Type',
    days: 'jours',
    hours: 'heures',
    minutes: 'minutes',
    liveConversions: 'Conversions',
    step: 'étape',
    cpuUsage: 'Charge CPU',
    memoryUsage: 'Charge RAM',
    
    sharedPortalProjectInformations : 'Informations de partage de projet inter-portails',
    controlKey : 'Clé de contrôle',
    p404Title : '404 Page Introuvable',
    Portal: 'Portail',
    ProjectsManager : 'Manager de projets',
    TagsManager : 'Manager de tags',    
    UsersManager : 'Utilisateurs',
    Customize : 'Réglages',
    Subscription : 'Abonnement',
    Portals : 'Portails',
    Portal : 'Portail',    
    Projects : 'Projets',
    Project : 'Projet',    
    Layers : 'Couches',
    Calculation : 'Calcul',
    Calculations : 'Calculs',
    calculationsCreate : 'Lancer un calcul',
    calculationsLog : 'Suivi des calculs',
    Status : 'Statut',
    Output : 'Résultat',
    Actions : 'Actions',
    MntDiffDescription: 'Calcul de volume entre deux MNT',
    selectFirstLayer: 'Sélectionnez la première couche',
    selectSecondLayer: 'Sélectionnez la seconde couche',
    overlap: 'Recouvrement',
    noCollindingLayersFound: 'Aucune couche ayant un recouvrement n\'a été trouvée',
    calculationSettings: "Paramètres de calcul",
    quality: "Qualité",
    densityUniformization: "Uniformisation de la densité",

    cutLineWidth: "Largeur neutralisée entre déblais/remblais",
    maxBodies: "Nombre max ce sous-éléments",
    bodiesVolMin: "Volume min. des sous-élements (m3)",
    bodiesVolMax: "Volume max. des sous-élements (m3)",
    zAxisVectorAuto: "Axe Z automatique",

    Layer : 'Couche',
    Model : 'Modèle',
    Users : 'Utilisateurs',
    Tags : 'Tags',    
    Administration : 'Administration',
    AccessButton : 'Accéder',
    RequestAccessButton : 'Demander un accès',
    SendingRequestAccessButton : "Demande en cours d'envoi...",
    PendingRequestAccessButton : "Demande d'accès envoyée",
    RejectedRequestAccessButton : "Demande d'accès refusée",
    AcceptedRequestAccessButton : "Demande d'accès acceptée",
    projectAccessRequestDetails : "Demande d'accès",
    pleaseAdjustUserRights: "Veuillez ajuster les droits de l'utilisateur avant de clore la demande",
    accept : "Accepter",
    refuse : "Refuser",
    pending : "en attente",
    accepted : "acceptée",
    refused : "refusée",
    closeRequest : "Clore la demande",    
    RequestClosed: "Cette demande est maintenant close. Si vous devez modifier les droits de l'utilisateur, veuillez vous rendre sur la page du projet.",
    ProjectAccessRequests: "Demandes d'accès en attente",
    ProjectAccessRequestsExplainations: "Vous administrez les utilisateurs de ce projet : votre action est requise sur ces demandes.",
    Model3D : 'Modèle 3D',    
    Datas3d : 'Données 3D',
    Mesh : 'Maillage 3D',
    mesh : 'Maillage 3D',
    Meshes : 'Maillages 3D',
    Pointcloud : 'Nuage de points',
    pointcloud : 'Nuage de points',
    Pointclouds : 'Nuages de points',
    StructuredPointcloud : 'Nuage de points structuré',
    StructuredPointclouds : 'Nuages de points structurés',
    Shapefile : 'Shapefile',
    shapefile : 'Shapefile',
    Shapefiles : 'Shapefiles',
    pano360 : 'Pano 360',
    Pano360 : 'Pano 360',
    Dao : 'Fichier DAO',
    dao : 'Fichier DAO',
    Daos : 'Fichiers DAO',
    Bim : 'Modèle BIM',
    bim : 'Modèle BIM',
    Bims : 'Modèles BIM',
    Tags : 'Tags',
    Photos : 'Photos',
    AccessToModel : 'Accéder au modèle',
    AccessToModelDirect : 'Accès direct',
    AccessToModelRdp : 'Accès côté serveur',
    rdpOptimalSolutionMessage : 'Solution optimale adaptée à votre configuration',
    rdpNotRecommendedSolutionMessage : 'Solution déconseillée pour votre configuration',
    rdpRecommendedSolutionMessage : 'Solution recommandée pour votre configuration',
    rdpExplaination : "L'accès direct offre une expérience optimale mais requiert un appareil performant. L'accès côté serveur est une alternative recommandée pour les appareils faibles.",
    rdpUnavailable : "L'accès côté serveur n'est pas disponible pour votre appareil",
    rdpAccessTitle : "Mode d'emploi de l'accès via le serveur",
    rdpAccessMessage : 'Un fichier .rdp sera téléchargé dans quelques secondes sur votre appareil. Vous devrez le lancer (double clic) et coller le mot de passe suivant :',
    rdpAccessLimitationsmessage : "L'accès serveur n'est pas possible sur Windows Famille. Tapez « Paramètres du bureau à distance » dans le menu Démarrer pour vérifier votre compatibilité.",
    AccessAllowed : 'Accès autorisé',
    AccessDeniedTitle : 'Accès interdit',
    PortalAccessDeniedFull : "Désolé, vous n'avez pas les droits pour accéder intégralement à ce portail",
    PortalAccessNotYetOpened : "Désolé, votre accès à ce portail n'est pas encore actif",
    PortalAccessExpired : "Désolé, votre accès à ce portail a expiré",
    ProjectAccessDeniedFull : "Désolé, vous n'avez pas les droits pour accéder intégralement à ce projet",
    ProjectAccessNotYetOpened : "Désolé, votre accès à ce projet n'est pas encore actif",
    ProjectAccessExpired : "Désolé, votre accès à ce projet a expiré",   
    NoLayersInThisModel : "Ce modèle ne contient aucune donnée 3D pour l'instant",
    ModelAccessForbidden : "Vous n'avez pas les droits requis pour visualiser ce modèle. Veuillez contacter un administrateur de ce projet.",
    ProjectLocation : "Localisation du projet",
    ModelLocationUnknown : "Localisation non renseignée",
    PlaceSearchPlaceholder : "Recherche d'adresse",

    tagGroup: "Groupe",
    Edit : "Editer",
    Save : "Enregistrer",
    Cancel : "Annuler",
    Finish : "Terminer",
    Confirm : "Confirmer",
    UpdateCanceled : "Mise à jour annulée",
    UpdateConfirmed: "Mise à jour confirmée",
    UpdateError: "Erreur lors de la mise à jour",
    ProjectLocationUpdated:     "La position du projet a été modifiée",
    ProjectLocationNotUpdated:  "La position du projet n'a pas été modifiée",
    ProjectUpdateStarted : "Mise à jour possible",
    ProjectLocationUpdateStarted:  "Déplacez le marqueur pour repositionner le projet",
    PressSaveOrCancel:  "Cliquez sur enregistrer ou annuler pour appliquer",
    PressSaveToApply:  "Cliquez sur enregistrer pour appliquer",
    EditionFinished:  "Edition terminée",
    EditionStarted:  "Début de l'édition",
    UpdatesWillBeInstant:  "Les mises à jour seront instantanées",
    UpdatesSaved:  "Mises à jour sauvegardées",
    default_visibility:  "Visible par défaut",
    default_pointcloud_material_pointsizetype:  "Taille adaptative des points",
    default_pointcloud_material_size:  "Taille des pixels",
    default_pointcloud_material_style:  "Style d'affichage",
    default_pointcloud_material_color:  "Couleur",
    layer_4d:  "Planification 4D",
    layer_4d_min:  "Début de planification 4D",
    layer_4d_max:  "Fin de planification 4D",
    
    shp_point: "Points",
    shp_point_size: "Tailles des points",
    shp_point_style: "Style des points",
    shp_point_color: "Couleur des points",
    shp_point_colored: "Couleur unie",
    shp_polygon_filled: "Remplissage",
    shp_polygon_fill_color: "Couleur de remplissage ",
    shp_polygon_outline: "Contours",
    shp_polygon_outlined: "Dessin du contour",
    shp_polygon_outline_color: "Couleur du contour",
    shp_polygon_outline_width: "Epaisseur du contour",
    shp_polygon_clamp_to_ground: "Drappage auto sur le globe",
    shp_contains_height: "Affichage de l'objet 3D brut",
    shp_height_method: "Comportement 3D",
    shp_tag: "Annotations",
    shp_tag_explained: "Afficher une annotation 3D décrivant chaque entité du fichier",
    coordinatesSystemRequired:  "Un système de coordonnées doit préalablement être défini",
    coordinatesSystemRequiredExplained:  "Ce type de couche ne peut pas être intégré dans un projet sans système de coordonnées",
    georeferencedProject:  "Modèle géoréférencé",

    RemoveProjectLayer:  "Retirer la couche de ce projet",
    RemoveProjectLayers:  "Retirer des couches par lot",
    SelectProjectLayer:  "Sélectionner la couche pour l'édition par lot",
    ParameterUpdated:  "Paramètre mis à jour",
    ParameterNotUpdated:  "Paramètre non mis à jour",
    Style:  "Style",
    RGB:  "RGB",
    HEIGHT:  "Hauteur",
    COLOR:  "Couleur fixe",
    ConfirmRemoval:  "Confirmer le retrait",
    ConfirmedRemoval:  "Retrait confirmé",
    ProjectLayerRemovalMessage:  "Les couches sélectionnées sereont retirées de ce projet. Elle resteront affectées aux éventuels autres projets qui l'utilisent et resteront également disponible dans la bibliothèque de couches de ce portail.",
    ProjectLayerRemovalConfirmationMessage:  "La couche reste affectée aux éventuels autres projets qui en dépendent et reste également disponible dans votre bibliothèque de couches",
    FailedRemoval:  "Retrait échoué",
    NoLayersAllocatedForThisType:  "Aucune couche de ce type n'est présente dans le modèle",
    RemoveLayer4dPlanification:  "Retirer la planification 4D de cette couche",
    RemoveLayer4dPlanificationConfirmation:  "Planification 4D de la couche retirée",
    ProjectTags:  "Tags",
    actions:  "Actions",
    type :          "Type",
    placeholder :   "Marqueur",
    color :   "Couleur",
    description :   "Description",
    groupe :        "Groupe",
    value :         "Valeur",
    url :         "URL",
    RemoveProjectTag : "Retirer le tag",
    text :          "Texte",
    texte :          "Texte",
    web :           "Web",
    photo :         "Photo",
    realtime :      "Temps réel",
    mediaPreview :  "Aperçu du média",
    projectTagsSeeForbidden :  "Désolé, vous n'avez pas les droits pour accéder aux tags. Veuillez demander l'accès à un administrateur du projet.",
    ProjectTagRemovalMessage:  "Ce tag sera supprimé définitivement sans annulation possible",
    ProjectTagRemovalConfirmationMessage:  "Le tag a été supprimé",
    ProjectUsers : "Utilisateurs du projet",
    User : "Utilisateur",
    userFirstName : "Prénom",
    userLastName : "Nom",
    userId : "ID",
    userEmail : "E-mail",

    Capabilities : "Capacités",
    ViewCapabilities : "Visualisation",
    ContributionCapabilities : "Contribution",
    ManagementCapabilities : "Administration",
    start_access: "Début d'accès",
    end_access: "Fin d'accès",
    projects_total: "Nombre de projets max",
    layers_total: "Nombre de couches max",
    users_total: "Nombre d'utilisateurs max",
    space_total: "Espace disque max (Go)",
    edit: 'Editer',
    can_project_access: "Accéder au projet",
    can_project_access_start_date: "Début d'accès",
    can_project_access_end_date: "Fin d'accès",
    can_project_customize: "Configurer le projet",
    can_project_users_manage: "Administrer les utilisateurs du projet",
    can_project_layers_manage: "Administrer les couches du modèle",
    can_project_tags_manage: "Administrer les tags du projet",
    can_project_tags_see: "Voir les tags",
    can_project_users_see: "Voir les utilisateurs du projet",
    can_project_chat_read: "xxxxxxx",
    can_project_chat_write: "xxxxxxx",
    can_project_chat_moderate: "xxxxxxx",
    can_project_model_see: "Voir le modèle 3D",
    can_project_tools_use: "Accès aux outils 3D",
    can_project_tags_add: "Ajout de tags",
    can_project_shares_see: "Voir les partages du modèle",
    can_project_shares_send: "Partager le projet",
    can_project_shares_manage: "Administrer les partages",
    RemoveProjectUser: "Retirer l'utilisateur",
    RemoveProjectCurrentUserImpossible: "Impossible de vous retirer vous même",
    CanRemoveCapabilityYourself: "Impossible de vous retirer vous même cette capacité",
    from: "du",
    to: "au",
    ProjectUserRemovalMessage:  "L'accès de cet utilisateur à ce projet sera supprimé sans annulation possible",
    ProjectUserRemovalConfirmationMessage:  "Utilisateur retiré du projet",
    UploadSuccess:  "Envoi réussi",
    UploadError:  "Envoi échoué",
    choseImage:  "Choisissez une image",
    choseJpgImage:  "Choisissez une image au format .jpg",
    choseImageToChangeProjectCover:  "Choisissez un fichier si vous souhaitez modifier l'image de couverture. Ne faites rien si vous souhaitez conserver l'image actuelle.",
    treatmentInProgress:  "Traitement en cours...",
    NoResultsFound:  "Aucun résultat trouvé",
    projectNicenameEditMessage: "Changez le titre du projet",
    projectSlugEditMessage: "Ce numéro unique est utilisé dans l'URL. En le modifiant, vous briserez les éventuels raccourcis créés par d'autres utilisateurs.",
    projectSlugUnavailable: "Cette valeur n'est pas disponible, merci d'en saisir une autre.",
    projectSlugAvailable: "Valeur disponible",
    youCanSaveThisSlug: "Vous pouvez sauvegarder vos paramètres",
    sameValueAsCurrent: "Valeur identique à la value actuelle",
    noUpdateWillBeMade: "Aucune mise à jour ne sera faite",
    projectsList: "Liste",
    projectsMap: "Carte",
    projectsWithoutPositionAlertTitle: "projets n'ont pas été géo-positionnés et n'apparaissent donc pas sur la carte. Cliquez ici pour voir lesquels.",
    noProjectPositioned: "Aucun de vos projets n'a encore été géo-positionné. Veuillez d'abord leur ajouter un emplacement.",
    noProjectInPortal: "Aucun projet n'a été créé dans ce portail pour l'instant.",
    noAuthorizedProjectInPortal: "Vous n'avez accès à aucun projet de ce portail pour l'instant.",
    noTagsInThisProject: "Aucun tag n'a encore été ajouté au modèle 3D de ce projet.",
    gotoModelToAddTags: "Rendez-vous dans le modèle 3D pour ajouter les premiers tags.",
    askForRightsToAddTags: "Veuillez demander à un administrateur du projet de vous accorder le droit d'ajouter des tags.",
    addLayer: "Ajouter des couches",
    unusedLayers: "Non affectées",
    usedLayers: "Affectées",
    noUnusedLayersToShow: "Aucune couche non affectée à afficher, mais vous pouvez ajouter des couches déjà affectées à d'autres projets.",
    layerAlreadyAllocated: "Couche déjà présente dans ce projet",
    layerCannotBeAllocatedTwice: "Une même couche ne peut être allouée plusieurs fois à un projet",
    ConfirmLayerAddition: "Confirmer l'ajout de la couche",
    ProjectLayerAdditionMessage: "cette couche deviendra visible pour chaque utilisateur du projet",
    ConfirmedAllocation: "Ajout confirmé",
    ProjectLayerAllocationConfirmationMessage: "La couche est désormais visible pour tous les utilisateurs du projet",
    FailedAllocation: "Erreur durant l'ajout de la couche",
    legend: "Légende",
    coordinatesSystem: "Système de coordonnées",
    epsgName: "Nom",
    epsgCode: "Code EPSG",
    noCoordinatesSystemFound: "Aucun résultat",
    enterCoordinatesSystemQuery: "Saisissez une requête",
    currentCoordinatesSystem: "Système actuel",
    selectedValueSameAsCurrent: "Valeur sélectionnée identique à la valeur actuelle",
    noChangeWillBeMade: "Aucun changement ne sera enregistré",
    updateCoordinatesSystemConfirmationMessage: "Le système de coordonnées du projet va être modifié pour",
    updateCoordinatesSystemConfirmedMessage: "Le système de coordonnées a été modifié",
    noCoordinatesSystem: "Aucun système de coordonnées défini pour le projet",
    coordinatesSystemEnablesGlobe: "Indiquer le système de coordonnées du projet permet d'intégrer le modèle sur un globe 3D et d'activer les fonctionnalités géospatiales.",
    removeCoordinatesSystemConfirmationMessage: "Le système de coordonnées du projet va être retiré",
    noLegendImg: "Aucune image de légende ajoutée",
    removeProjectLegend: "Retirer la légende",
    legendRemoveConfirmMessage: "La légende va être retirée",
    NewProject: "Nouveau projet",
    ConfirmProjectCreation: "Créer un nouveau projet",
    newProjectFormTitle: "Création d'un nouveau projet",
    newProjectSlug : "Identifiant unique pour l'URL",
    newProjectNicename: "Titre du projet",
    newProjectBtn: "Créer le projet",
    projectCreationSuccess: "Création du projet réussie",
    projectCreationError: "Création du projet échouée",
    removeProject: "Supprimer ce projet",
    cloneProject: "Cloner le project",
    cloneProjectMessage: "Un nouveau projet basé sur celui-ci va être créé.",
    cloneProjectChoice: "Que voulez-vous dupliquer ?",
    cloneProjectLayers: "Couches",
    cloneProjectUsers: "Utilisateurs",
    cloneProjectTags: "Tags",
    cloneProjectSharedScene: "Scène partagée",
    cloneProjectConfirm: "Voulez-vous vraiment dupliquer ce projet ?",
    cloneProjectSuccess: "Projet dupliqué avec succès",
    cloneProjectError: "Erreur de duplication du projet",
    removeProjectTypeMessage: "Recopiez la valeur suivante pour confirmer",
    projectRemoveConfirmationMessage: "Ce projet sera supprimé du portail. Plus aucun utilisateur ne pourra y accéder. La suppression est irréversible. Les couches 3D affectées au projet resteront utilisables et affectables pour les autres projets.",
    valueIsNotValid: "Valeur invalide",
    projectDeletionCanceled: "Suppression du projet annulée",
    projectDeletionSuccess: "Suppression du projet réussie",
    projectDeletionError: "Erreur lors de la suppression du projet",
    multiPortalProjectTitle:   "Projet partagé multi-portails",
    multiPortalProjectMessage:   "Les couches, tags et actions sur le projet sont partagées entre les utilisateurs de :",
    removeMultiPortalProject: "Retirer ce projet partagé du portail",
    projectMultiPortalDeletionCanceled: "Retrait du projet partagé annulée",
    projectMultiPortalDeletionSuccess: "Retrait du projet partagé du projet réussie",
    projectMultiPortalDeletionError: "Erreur lors du retrait du projet partagé",
    projectMultiPortalRemoveConfirmationMessage: "Le projet partagé sera retiré de ce portail. Les utilisateurs de votre portail ne pourront plus y accéder. En revanche, il restera accessible aux utilisateurs des autres portails. Le retrait ne peut pas être annulé.",
    projectAddToOtherPortal: "Ajouter ce projet à un autre portail",
    projectAddToOtherPortalTypeMessage: "Veuillez saisir les informations communiquées par l'administrateur du portail de destination.",
    projectAddToOtherPortalCanceled: "Partage du projet vers un autre portail annulé",
    projectAddToOtherPortalConfirmationMessage: "Ce projet deviendra un espace commun à plusieurs portails. Les capacités d'ajout/retrait d'utilisateurs et/ou de couches 3D et/ou de tags s'appliqueront de manière globale pour tous les portails.",
    projectAddToOtherPortalConfirmationMessage2: "Action irréversible. Vous ne pourrez pas supprimer ce projet du portail de destination. Seul un administrateur de ce portail sera en mesure de le faire, sans que vous ne puissiez l'en obliger.",
    readThisVeryCarefully: "Lisez très attentivement ceci",
    Verification: "Vérification",
    projectSharePortalSlug: "Identifiant du portail de destination",
    projectSharePortalUserId:       "Identifiant de l'administrateur du portail de destination",
    projectAddToOtherKey:         "Clé de sécurité communiquée par l'administrateur",
    invalidKey: "Clé invalide",
    validKey: "Clé valide",
    projectAddToOtherPortalSuccess: "Ajout du projet au portail tiers réussi",
    projectAddToOtherPortalError: "Ajout du projet au portail tiers échoué",
    projectAddUserSuccess: "Ajout de l'utilisateur réussi",
    projectAddUserError: "Ajout de l'utilisateur échoué",
    projectUserExists: "L'utilisateur a déjà accès au projet",
    invalidPortalSlug: "Identifiant de portal invalide",
    addUser: "Ajouter un utilisateur",
    addUserConfirmationMessage:"Ajouter",
    changeUserCapabilities:"Veuiller maintenant ajuster les capacités de l'utilisateur dans la liste",
    layer_id:"ID",
    layer_name:"Titre",
    layer_type:"Type",
    layerProperties:"Propriétés",
    layer_origin:"Origine",
    layer_movable:"Déplaceable",
    layer_deformable:"Déformable",
    
    layerMovableDeformable: "La position et les dimensions de l'objet peuvent être modifiés.",
    layerMovableNotDeformable: "La position de l'objet peut être modifiée, mais pas ses dimensions.",
    layerNotMovableDeformable: "La position de l'objet est fixe, mais ses dimensions peuvent être modifiées.",
    layerNotMovableNotDeformable: "L'object est figé. Ni sa position ni ses dimensions ne peuvent être changées.",

    layer_origin_x:"X",
    layer_origin_y:"Y",
    layer_origin_z:"Z",
    resetOrigin:"Réinitialiser depuis le fichier",
    layer_size:"Poids",
    conversion_status:"Conversion",
    allocations_count:"Affectations",
    conversion_end_date:"Date",
    layerEdition:"Editer la couche",
    layerInformations:"Informations",
    conversionInformations:"Informations de conversion",
    layerOriginInformation:"Coordonnées de l'origine",
    upload_date:"Date d'envoi",
    conversion_start_date:"Début de conversion",
    conversion_end_date:"Fin de conversion",
    uploaded_filename:"Fichier",
    sender:"Expéditeur",
    projectsAllocations:"Affectée aux projets",
    noInformationsFound:"Aucune information trouvée",
    noAllocation:"Aucune affectation pour l'instant",
    layerNameEditMessage:"Le nom de la couche sera modifié pour tous les projets sur tous les portails qui l'utilisent",
    cannotManageLayers:"Vous n'avez pas les droits nécessaires pour manager les couches sur ce portail",
    cannotDeleteAllocatedLayer:"Vous ne pouvez pas supprimer cette couche car elle est allouée à certains projets",
    removeLayersAllocations:"Veuillez d'abord retirer la couche de chacun des projets ci-dessus",
    deleteLayer:"Supprimer la couche",
    deleteSelectedLayers:"Supprimer les couches sélectionnées",
    deleteLayerConfirmMessage:"Cette couche sera définitivement supprimée sans annulation possible",
    deletedLayer:"Couche supprimée",
    notDeletedLayer:"La couche n'a pas été supprimée",
    layerDeletionSuccess:"Suppression réussie",
    layerDeletionError:"Suppression échouée",
    subscriptionUsage:"Utilisation de l'abonnement",
    spaceUsage:"Espace disque",
    layersUsage:"Couches",
    projectsUsage:"Projets",
    usersUsage:"Utilisateurs",
    cannotRemoveProjectLayerAllocation:"Vous n'avez pas les droits nécessaires sur ce projet pour retirer cette couche. Veuillez contacter un des responsables du projet pour vous accorder ce droit.",
    total:"Forfait",
    available:"Disponible",
    used:"Utilisé",
    Action:"Action",
    userInformations:"Profil",
    userEdition:"Edition de l'utilisateur",
    userSelfEdition:"Editez votre profil",
    userAccessibleProjects:"Membre des projets",
    deleteUser:"Supprimer l'utilisateur",
    deleteUserConfirmMessage:"L'utilisateur ne pourra plus se connecter à ce portail RIM-Nat ni accéder aux projets de ce portail. Cette suppression n'affectera pas ses droits sur les éventuels autres portails auxquels il accède.",
    deletedUser:"Utilisateur supprimé de ce portail",
    notDeletedUser:"Utilisateur non supprimé",
    userDeletionSuccess:"Suppression réussie",
    userDeletionError:"Suppression échouée",
    profileUpdated:"Profil mis à jour",
    profileNotUpdated:"Profil non mis à jour",
    userCreation:"Ajouter un utilisateur",
    userCreationSubmit:"Ajouter",
    validEmail:"Email valide",
    invalidEmail:"Email invalide",
    account_exists:"Cet utilisateur a déjà un compte RIM-Nat (inactif ou sur un autre portail). Il sera ajouté à ce portail avec son identité actuelle.",
    accountWillBeCreated:"Cette adresse email n'est associée à aucun compte RIM-Nat existant. L'utilisateur devra créer son compte sur la page d'accueil de RIM-Nat.",
    accountCreationConfirmationMessage:"Le nouveau compte créé pourra accéder à ce portail mais à aucun projet. Vous devrez ensuite définir ses droits pour chaque projet.",
    subscriptionUsersExceeded:"Le nombre maximal d'utilisateurs prévu à l'abonnement a été atteint",
    subscriptionProjectsExceeded:"Le nombre maximal de projets prévu à l'abonnement a été atteint",
    subscriptionLayersExceeded:"Le nombre maximal de couches prévu à l'abonnement a été atteint",
    userCreationSuccess:"Compte utilisateur créé",
    userCreationError:"Erreur de création de compte utilisateur",
    userPortalAllocationSuccess:"Utilisateur ajouté au portail",
    userPortalAllocationError:"Erreur d'ajout de l'utilisateur au portal",
    user_exists_on_this_portal:"Cet utilisateur a déjà un compte sur ce portail. Vous pouvez le chercher sur cette page.",
    cannotManageUsers:"Vous n'avez pas les droits nécessaires pour gérer les utilisateurs",
    cannotManagePortal:"Vous n'avez pas les droits nécessaires pour gérer ce portail",
    currentUsage:"Utilisation actuelle de l'abonnemement",
    timeUsage:"Durée de l'abonnement",
    days:"jours",
    timeStart:"Début",
    timeEnd:"Fin",
    subscriptionExpired:"Abonnement terminé",
    changePlan:"Changer d'offre",
    pleaseContactUs:"Veuillez nous contacter",
    portalSettings:"Paramètres du portail",
    portalNicename:"Nom complet du portail",
    portalSlug:"Identifiant d'URL",
    portalSlugAlertMessage:"Attention, le changement de cette valeur brisera l'ensemble des raccourcis potentiellement créés par les utilisateurs. Aucune redirection n'aura lieu. Les utilisateurs actuellement connectés devront rouvrir RIM-Nat à la nouvelle adresse.",
    danger:"Danger critique",
    error:"Erreur",
    portalSlugNotAvailable:"Cette valeur n'est pas valide ou indisponible",
    portalNicenameUpdateConfirm:"Voulez-vous vraiment modifier le titre de ce portail ?",
    portalSlugChanged:"Identidiant d'URL mis à jour. Veuillez demander à vos utilisateurs de modifier leurs raccourcis.",
    portalNicenameChanged:"Titre du portail modifié.",
    projectsSettings:"Paramètres des projets",
    projectsLocation:       "Activer les fonctions de géolocalisation",
    projectsLegend:             "Activer l'ajout de légende au projet",
    projectsCoordinatesSystem:  "Activer la définition du système de coordonnées",
    betaTester:  "Accès aux fonctions beta",
    rdp:  "Accès au rendu côté serveur",
    viewerVersion:  "Version du viewer",
    projectsAvailableOnPlan: "projets restants à l'abonnement",
    youSeeThisProjectBecauseYouAreAdmin: "Vous pouvez accéder à cette page car vous avez des droits d'aministration sur le portail.",
    loginRequired: "Connexion requise",
    loginRequiredMessage: "Vous devez être connecté.e. pour accéder à l'application.",
    Logout: "Déconnexion",
    loginOrRegister: "Se connecter ou créer un compte",    
    migrationLoginMessage: "Attention : si vous utilisiez l'ancienne version de RIM-Nat et que vous voyez cette page pour la première fois : veuillez choisir \"Inscription\" à la prochaine étape puis créez un nouveau compte avec votre adresse email habituelle pour que vos données soient rappatriées.",   
    privatePortal: "Portail privé",
    privatePortalMessage: "Ce portail est privé",
    portalCaps: "Capacités sur le portail",
    beta_tester: "Accès aux fonctions beta",
    projects_location: "Peux spécifier la position des projets sur une carte",
    projects_legend: "Peut ajouter une légende aux projets",
    projects_coordinates_system: "Peut spécifier le système de coordonnées des projets et accéder au globe 3D",
    can_portal_access:     "Autorisé",
    can_portal_manage:     "Gestion de l'abonnement",
    can_portal_customize:     "Personnalisation",
    can_portal_access_start_date:     "Début d'accès",
    can_portal_access_end_date:       "Fin d'accès",
    can_portal_projects_see:          "Visibilité des projets",
    can_portal_projects_manage:       "Admistrateur des projets",
    can_portal_layers_manage:         "Admistrateur des couches",
    can_portal_users_manage:          "Admistrateur des utilisateurs",
    can_portal_projects_see_alert_on:           "Collaborateur : voit la liste complète des projets. Doit ensuite être membre d'un projet pour y accéder.",
    can_portal_projects_see_alert_off:          "Invité : ne voit que la liste des projets dont il est membre. Les autres projets lui sont invisibles.",
    can_portal_projects_manage_alert:           "Accède à tous les projets du portail. Peut les modifier, les supprimer et en ajouter de nouveaux.",
    can_portal_layers_manage_alert:             "Peut ajouter, modifier et supprimer des couches à ce portail.",
    can_portal_users_manage_alert:              "Peut ajouter, modifier et supprimer des utilisateurs à ce portail, y compris vous.",
    canAccessToAllProjects:   "L'utilisateur est administrateur des projets. Il peut accéder à tous les projets du portail même sans en être un membre déclaré.",
    memberOfProject:   "Membre déclaré sur :",
    userActivity:           "Dernières activités",
    employee:     "Collaborateur",
    guest:        "Invité",
    capsUpdated:            "Capacités mises à jour",
    capsNotUpdated:         "Capacités non mises à jour",
    date: "Date",
    event: "Evénement",
    details: "Détails",
    failedConversions: "Conversions échouées",
    finishedConversions: "Conversions terminées",
    aliveConversions: "Conversions en cours",
    data_title: "Titre",
    data_type: "Type",
    progression: "Progression",
    newJobAdded: "nouvelle(s) conversion(s) ajoutée(s)",
    jobFinished: "conversion(s) terminée(s)",
    pendingConversion: "Conversion en file d'attente",
    noAliveConversion: "Aucune conversion en cours ou en attente. Les envois récents peuvent prendre plusieurs minutes avant d'apparaitre ici, veuillez patienter.",
    layersManager: "Gestionnaire de couches",
    layersUploader: "Envoi de données 3D",
    i18n_pointcloudUploadExplainations:   "Nuage de points au format LAS ou LAZ (versions 1.3 et 1.4 non supportées), contenu ou non dans un fichier ZIP.",
    i18n_pointcloudUploadExplainationsAlert:   "Pensez à vérifier votre nuage de points dans un logiciel tel que CloudCompare (gratuit) avant l'envoi.",
    i18n_meshUploadExplainations:         "Contener ZIP comprenant : OBJ+MTL+JPG, ou OBJ+MTL+PNG, ou PLY+JPG, ou PLY+PNG.",
    i18n_bimUploadExplainations:          "Maquette BIM au format IFC2x3, contenue ou non dans un fichier ZIP.",
    i18n_shapefileUploadExplainations:    "Fichier ZIP contenant tous les fichiers associés à un shapefile : SHP+SHX+DBF+[...]",
    i18n_shapefileUploadExplainationsAlert:    "Le shapefile doit impérativement être placé dans le même système de projection que le projet avant l'envoi. Pensez à vérifier avec un logiciel tel que Qgis (gratuit).",
    i18n_pano360UploadExplainations:          "Fichier ZIP contenant toutes les images au format JPG + le fichier de calage au format TXT",
    i18n_pano360UploadExplainationsAlert:     "Attention à la syntaxe du fichier de calage. Colonnes : File, Time, x, y, z, course, pitch, roll.",
    i18n_structuredPointcloudUploadExplainations:          "Fichier E57 structuré contenant les stations de scan ainsi que les bulles photos associées, contenu ou non dans un fichier ZIP.",
    i18n_structuredPointcloudUploadExplainationsAlert:     "Pensez à vérifier la structure du E57 : chaque station doit contenir un nuage de points + un panoramique 360°",
    i18n_daoUploadExplainations:          "Fichier DXF, contenu ou non dans un fichier ZIP.",
    i18n_daoUploadExplainationsDetails:   "Limites du DXF : 150 Mo avant compression / 30 calques / 500 objets par calque. Entités conservées : lignes et polylignes 2D/3D d'altitude non nulle",
    i18n_daoUploadExplainationsAlert:     'Les calques "0" et "defpoints" sont retirés. Les objets doivent être en couleur "DuCalque". Pour accélérer fortement le temps de conversion : retirez les objets invalides, purgez le dessin et supprimez les calques inutilisés.',
    dxfLayersManager:          "Gestion des calques",

    dataExplainations:          "Fichiers attendus",
    emailNotAvailable:          "Cette adresse mail est déjà associée à un compte",
    choseData:  "Déposez votre fichier",
    nameYourData:  "Veuillez donner un titre à ce jeu de données.",
    Ok:  "Ok",
    freshUpload:  "Votre envoi récent apparaîtra bientôt ici. Merci de patienter quelques minutes.",
    uploadSuccess:  "Nous avons bien reçu votre fichier ! Il apparaitra bientôt dans la liste des conversions en cours. Merci de patienter quelques instants.",
    choosePortal: "Veuillez choisir votre portail",
    emailNotVerifiedTitle: "Validez votre adresse email",
    emailNotVerifiedMessage: "Veuillez confirmer votre identité en cliquant sur le lien contenu dans l'email qui vient de vous être envoyé à l'adresse",
    emailNotVerifiedMessage2: "Pensez à vérifier votre dossier ou votre filtre Spam / Indésirables",
    emailNotVerifiedMessage3: "Cliquez ensuite sur le bouton de connexion ci-dessous",
    notAuthorizedTitle: "Accès non autorisé",
    notAuthorizedMessage: "Votre compte ne vous permet pas d'accéder à l'application.",
    backToHome: "Retour à l'accueil",
    userWithoutPortalTitle: "Vous n'avez accès à aucun portail RIM-Nat pour l'instant",
    userWithoutPortalMessage: "Pour l'instant, votre compte RIM-Nat n'est affecté à aucun portail.",
    welcome: "Bienvenue",
    youKnowAnAdministratorTitle:    "Vous connaissez un utilisateur de RIM-Nat",
    youKnowAnAdministratorMessage:  "Contactez-le pour qu'il, ou son administrateur, vous ajoute à leur portail et aux projets qui vous concernent.",
    youHaveAnInviteTitle:           "Vous avez reçu une invitation ?",
    youHaveAnInviteMessage:         "Saisissez votre code pour mettre à jour vos droits.",
    howToGetAccess:                 "Comment accéder à un nouveau projet ou un nouveau portail ?",

    app_login:                              "Connexion à l'application",
    app_logout:                             "Déconnexion de l'application",
    portal_projects:                        "Page des projets",
    portal_project_creation:                "Création d'un projet",
    delete_project_layer:                   "Retrait d'une couche d'un projet",
    delete_portal_layer:                    "Retrait d'une couche du portail",
    layer_edition:                          "Edition d'une couche",
    portal_access:                          "Accès au portail",
    user_creation:                          "Création d'un utilisateur",
    user_portal_allocation:                 "Ajout d'un utilisateur au portail",
    portal_users:                           "Page des utilisateurs",
    project_clone:                          "Duplication d'un projet",
    project_portal_removal:                 "Retrait d'un projet",
    project_multiportal_share:              "Partage d'un projet avec un autre portail",
    project_multiportal_import:             "Import d'un projet depuis un autre portail",
    project_user_allocation:                "Ajout d'un utilisateur au projet",
    project_legend_update:                  "Mise à jour de la légende du projet",
    project_coordinates_system_update:      "Mise à jour du système de coordonnées du projet",
    project_coordinates_system_removal:     "Retrait du système de coordonnées du projet",
    project_slug_update:                    "Mise à jour de l'URL du projet",
    project_nicename_update:                "Mise à jour du titre du projet",
    project_cover_update:                   "Mise à jour de la couverture du projet",
    project_location_update:                "Mise à jour de la localisation du projet",
    project_user_arrival:                   "Ajout de l'utilisateur au projet",
    project_layer_allocation:               "Ajout d'une couche au projet",
    project_layer_removal:                  "Retrait d'une couche au projet", 
    project_layer_4d_removal:               "Retrait de la planification 4D d'une couche", 
    project_layer_parameter_update:         "Mise à jour d'un paramètre de couche du projet", 
    project_user_cap_update:                "Mise à jour des capacités d'un utilisateur sur le projet", 
    project_user_cap_updated:               "Mise à jour des capacités de l'utilisateur sur le projet", 
    project_user_remove:                    "Retrait d'un utilisateur du projet", 
    project_user_removed:                   "Retrait de l'utilisateur du projet", 
    project_tag_remove:                     "Retrait d'un tag du projet", 
    project_tag_update:                     "Mise à jour d'un tag du projet", 
    project_access:                         "Accès au projet", 
    project_access_refused:                 "Accès au projet refusé", 
    data_upload_start:                      "Début de l'envoi de données 3D", 
    data_uploaded:                          "Données envoyées au convertisseur", 
    layer_manager_access:                   "Accès au gestionnaire de couches", 
    layer_manager_access_refused:           "Accès au gestionnaire de couches refusé", 
    layer_sidebar_open:                     "Ouverture du tableau d'édition d'une couche", 
    layer_update:                           "Mise à jour d'une couche", 
    profile_update:                         "Mise à jour d'un profil utilisateur", 
    profile_updated:                        "Mise à jour du profil utilisateur", 
    profile_portal_caps_update:             "Mise à jour des capacités d'un utilisateur sur le portail", 
    profile_portal_caps_updated:            "Mise à jour des capacités par l'administrateur du portail", 
    portal_user_removal:                    "Retrait d'un utilisateur du portail", 
    portal_user_removed:                    "Utilisateur retiré du portail par un administrateur", 
    project_user_removal:                   "Retrait d'un utilisateur du projet", 
    project_user_removed:                   "Utilisateur retiré du projet par un administrateur", 
    portal_info_update:                     "Mise à jour des informations du portail",
    avatar_upload:                          "Mise à jour de l'avatar",
    portal_projects_parameters_update:      "Mise à jour des paramètres des projets",
    portal_slug_update:                     "Mise à jour de l'URL du portail",
    portal_nicename_update:                 "Mise à jour du titre du portail",
    share_manager_access:                   "Accès à la page de partage",
    share_manager_access_refused:           "Accès à la page de partage refusé",
    share_creation:                         "Création d'un partage",
    share_used:                             "Utilisation d'un partage",
    project_keyword_add:                    "Mot clé ajouté au projet",
    project_keyword_delete:                 "Mot clé supprimé du projet",
    project_access_request_closed:          "Demande d'accès close",
    project_access_request_refusal:         "Refus d'une demande d'accès refusée",
    project_access_request_refused:         "Demande d'accès refusée",
    project_access_request_acceptation:     "Acceptation d'une demande d'accès",
    project_access_request_accepted:        "Demande d'accès acceptée",
    project_access_request_manager:         "Accès au gestionnaire de demande d'accès",
    project_access_request_created:         "Demande d'accès à un projet envoyée",
    project_access_request_creation_error:  "Erreur lors de la demande d'accès à un projet",
    model_screenshot:                       "Capture d'écran du modèle",
    user_agent:                             "User agent",
    diagnostic_page_access:                 "Accès au diagnostic",

    activityDetails:            "Détails d'une activité",
    success:                    "Réussite",
    Shares:                       "Partages",
    thisProjectWillBeVisibleInList: "Ce projet deviendra visible aux utilisateurs de ce partage. Veuillez définir les capacités additionnelles ci-dessous.",
    projectsChoice: "Projet.s que vous pouvez partager",
    projectsCaps: "Capacités associées",
    Step: "Etape",
    Validation: "Validation",
    shareCreation:  "Créer un nouveau partage",
    shareManager:   "Gestionnaire des partages",
    noShares:   "Aucun partage à afficher pour le moment",
    Next:       "Suivant",
    Back:   "Retour",
    TextCopySuccess:    "Texte copié avec succès",
    TextCopyError:      "Erreur lors de la copie",
    Copy:               "Copier",
    CopyPassword:               "Copier le mot de passe",
    Limitations:               "Limitations",
    limitationsExplaination:   "Ajoutez certaines limites optionnelles pour renforcer la sécurité de ce partage.",
    shareLimitUsageNumber:     "Nombre maximal d'utilisateurs",
    passwordProtection:     "Protection par mot de passe",
    expireDate:     "Date d'expiration du partage",
    shareLimitations:     "Limites d'utilisation du code de partage",
    shareCode:     "Code de partage",
    shareLink:     "Lien de partage",
    shareLimitUsageNumberExplainations: "Limite le nombre d'utilisateurs distincts pouvant utiliser le code ou le lien de partage. Les utilisations par un même utilisateur ne sont pas décomptées.",
    shareLimitPasswordExplainations:    "Mot de passe de votre choix qui sera demandé à l'utilisateur. Veillez à ne jamais diffuser le lien de partage et le mot de passe dans le même document.",
    shareLimitDateExplainations:        "Date d'expiration du lien de partage. Cette date n'est pas liée aux éventuelles autre dates définies par projet à l'étape précédente.",
    shareCodeZone:                "Voici votre lien de partage !",
    shareCodeZoneExplainations:   "Vous pouvez le communiquer par mail ou l'intégrer sous forme de lien à n'importe quel document. Vous pouvez également télécharger le QR Code et demander à votre destinataire de le scanner.",
    qrCode:   "QR Code",
    createNewShare:   "Créer un autre partage",
    shareCreationReady:             "Votre partage est prêt",
    shareCreationClickToConfirm:    "Veuillez cliquer sur Suivant pour finaliser sa création et permettre sa diffusion",
    shareCreationSuccess:           "Lien de partage créé avec succès",
    shareCreationSuccessText:       "Vous pouvez maintenant le communiquer",
    shareCreationError:             "Erreur de création du lien de partage",
    shareWelcomeMessage:            "RIM-Nat permet de collaborer de manière sécurisée sur vos projets.",
    shareStartButton:               "Créer un nouveau partage",
    previousShares:                 "Historique des partages",
    createdBy:                 "Créé par",
    noLimitations:                 "Aucune",
    Validity:             "Validité",
    shareLimitUsageExceeded:  "Limite d'utilisateurs atteinte",
    shareExpireDateExceeded:  "Date d'expiration atteinte",
    noUsers:  "Aucun utilisateur",
    removeShare:  "Supprimer le partage",
    removeShareConfirmWithUsers:  "Attention : Les utilisateurs ayant utilisé ce partage perdront tous leurs droits sur les projets concernés. De plus, ce partage deviendra définitivement inutilisable à tout nouvel utilisateur.",
    removeShareConfirmWithoutUsers:  "Attention : Ce partage deviendra définitivement inutilisable.",
    shareRemovalSuccess:  "Suppression du partage réussie",
    shareRemovalError:    "Erreur de suppression du partage",
    shareDetails:         "Paramètres",
    shareInformations:    "Consignes de partage",
    valid: "Valide",
    invalid: "Invalide",
    usable: "Utilisable",
    unusable: "Inutilisable",
    locked: "Bloqué",
    invitationFrom : "Invitation de",
    expireDatePast : "Date d'expiration du partage dépassée",
    numberOfUsersExceeded : "Nombre d'utilisation maximal dépassé",
    passwordInvalid : "Mot de passe invalide",
    passwordProtectedShare : "Invitation protégée par mot de passe",
    typePasswordHere: "Saisissez le mot de passe ici",
    wrongPassword: "Mot de passe incorrect",
    shareUseNeedsConnection: "Vous devez être connecté.e pour utiliser ce partage. Si vous n'avez pas de compte, vous pouvez en créer un gratuitement.",
    rightsWillBeAdded: "Ce partage vous permet d'accéder aux projets suivants avec les capacités suivantes",
    acceptInvitation: "Accepter l'invitation",
    cannotUseYourOwnShare: "Vous ne pouvez pas utiliser ce partage car vous en êtes le créateur.",
    shareUsedTitle: "Invitation acceptée",
    shareUsedText: "Vos capacités ont été mises à jour",
    shareUnusable: "Ce partage n'est plus utilisable, vous ne pouvez plus le partager.",
    language: "Langue",
    en: "Anglais",
    fr: "Français",
    publicGalleryTitle: "Galerie publique",
    publicGalleryMessage: "Voici quelques projets rendus publics par",
    notLoggedinMessage: "Vous n'êtes pas connecté.e. ",
    publicModel: "Modèle public",
    userAccessibleModel: "Vous êtes membre de ce projet",
    adminAccessibleModel: "Vous accédez en tant qu'administrateur",
    userNotAccessibleModel: "Vous n'avez pas accès à ce projet",
    setProjectStatusPrivate: "Désactiver le statut public",
    setProjectStatusPublic: "Rendre public",
    setProjectStatusPublicConfirm: "Attention, votre modèle deviendra accessible à n'importe quel utilisateur de RIM-Nat en dehors de votre portail. Cette fonction est utile pour promouvoir votre expertise mais n'est pas recommandée d'un point de vue de la confidentialité des données.",
    mostRecentFirst: "Plus récent",
    alphabeticalSort: "Alphabétique",
    publicFirst: "Public",
    sortBy: "Trier par",
    newKeyword: "Nouveau mot-clé",
    keyword: "mot-clé",
    All: "Tous",
    someProjectsWithoutPosition: "Certains projets n'ont pas été géolocalisés et ne sont donc pas visibles sur la carte.",
    emptyFilteredProjectsList: "Aucun projet correspondant à vos filtres",

    // DEFAULT THEME VALUES 
    Dashboard: 'Tableau de bord',
    eCommerce: 'eCommerce',
    Apps: 'Applications',
    Todo: 'A faire',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendrier',
    FullCalendar: 'Calendrier complet',
    SimpleCalendar: 'Calendrier simple',
    Shop: 'Boutique',
    ItemDetails: 'Détails de l\'article',
    Cart: 'Panier',
    WishList: 'Liste de souhaits',
    Checkout: 'Paiement',
    UI: 'UI',
    DataList: 'Liste de couches',
    ListView: 'Voir la liste',
    ThumbView: 'Thumb View',
    Grid: 'Grille',
    Vuexy: 'RIM-Nat',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Couleurs',
    Card: 'Carte',
    Basic: 'Basic',
    Statistics: 'Statistiques',
    Analytics: 'Statistiques',
    Activity: 'Activité',
    CardActions: 'Actions de carte',
    CardColors: 'Couleurs de la carte',
    Table: 'Table',
    agGridTable: 'Tableau filtrable',
    Components: 'Composants',
    Alert: 'Alerte',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Bouton',
    ButtonGroup: 'Groupe de boutons',
    Chip: 'Puce',
    Collapse: 'Effondrer',
    Dialogs: 'Dialogues',
    Divider: 'Diviseur',
    DropDown: 'Menu déroulant',
    List: 'liste',
    Loading: 'Chargement',
    Navbar: 'Barre de navigation',
    Notifications: 'Les notifications',
    Pagination: 'Pagination',
    Popup: 'Apparaitre',
    Progress: 'Le progrès',
    Sidebar: 'Barre latérale',
    Slider: 'Curseur',
    Tabs: 'Onglets',
    Tooltip: 'Info-bulle',
    Upload: 'Envoi',
    FormsAndTable: 'Formulaires et tableau',
    FormElements: 'Éléments de formulaire',
    Select: 'Sélectionner',
    Switch: 'Commutateur',
    Checkbox: 'Case à cocher',
    Radio: 'Radio',
    Input: 'Contribution',
    NumberInput: 'Nombre d\'entrée',
    Textarea: 'Textarea',
    FormLayouts: 'Dispositions de formulaire',
    FormWizard: 'Assistant de formulaire',
    FormValidation: 'Validation du formulaire',
    FormInputGroup: 'Groupe de saisie de formulaire',
    Pages: 'Pages',
    Authentication: 'Authentification',
    Login: 'S\'identifier',
    Register: 'registre',
    ForgotPassword: 'Mot de passe oublié',
    ResetPassword: 'réinitialiser le mot de passe',
    LockScreen: 'Écran verrouillé',
    Miscellaneous: 'Divers',
    ComingSoon: 'Arrive bientôt',
    Error: 'Erreur',
    404: '404',
    500: '500',
    NotAuthorized: 'Pas autorisé',
    Maintenance: 'Entretien',
    Profile: 'Profil',
    User: 'Utilisateur',
    View: 'Vue',
    UserSettings: 'Paramètres utilisateur',
    Invoice: 'Facture d\'achat',
    FAQ: 'FAQ',
    Search: 'Chercher',
    KnowledgeBase: 'Base de connaissances',
    ChartsAndMaps: 'Graphiques Et Cartes',
    Charts: 'Graphiques',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Maps',
    Extensions: 'Extensions',
    QuillEditor: 'Éditeur de piquants',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Sélecteur de date',
    DatetimePicker: 'Sélecteur de date / heure',
    AccessControl: 'Contrôle d\'accès',
    I18n: 'I18n',
    Carousel: 'Carrousel',
    Clipboard: 'Presse-papiers',
    ContextMenu: 'Menu contextuel',
    StarRatings: 'Classement par étoiles',
    Autocomplete: 'Terminé automatiquement',
    Tree: 'Arbre',
    // "Import/Export": 'Importation/Exportation',
    Import: 'Importation',
    Export: 'Exportation',
    ExportSelected: 'Exporter sélectionné',
    Others: 'Autre',
    MenuLevels: 'Niveaux de menu',
    MenuLevel2p1: 'Niveau de menu 2.1',
    MenuLevel2p2: 'Niveau de menu 2.2',
    MenuLevel3p1: 'Niveau de menu 3.1',
    MenuLevel3p2: 'Niveau de menu 3.2',
    DisabledMenu: 'Menu désactivé',
    Support: 'Soutien',
    Documentation: 'Documentation',
    RaiseSupport: 'Augmenter le soutien',
    demoTitle: 'Titre de la carte',
    demoText: 'Le gâteau au sésame s\'enclenche au petit pain au pain d\'épices danois J\'adore le pain d\'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d\'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l\'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.'
  }
}
