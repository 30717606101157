import auth0 from 'auth0-js'
import EventEmitter from 'events'
import authConfig from '@/../auth_config.json'

import store from '@/store/store.js'
import i18n from '@/i18n/i18n.js'
import logActivity from '@/logActivity.js'

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const localStorageKey = 'loggedIn'

const tokenExpiryKey = 'tokenExpiry'
const loginEvent = 'loginEvent'

const webAuth = new auth0.WebAuth({
  domain: authConfig.domain,
  redirectUri: `${window.location.origin + process.env.BASE_URL}callback`,
  clientID: authConfig.clientId,
  responseType: 'id_token',
  scope: 'openid profile email',
  language: i18n.locale,
  ui_locales: i18n.locale
})

function utf8_to_b64 (str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

class AuthService extends EventEmitter {
    idToken = null;
    profile = null;
    tokenExpiry = null;

    // Starts the user login flow
    login (customState) {
      webAuth.authorize({
        appState: customState,
        language: i18n.locale,
        ui_locales: i18n.locale
      })
    }

    // Handles the callback request from Auth0
    handleAuthentication () {
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {
          if (err) {
            alert(`${err.error}. Detailed error can be found in console.`)
            reject(err)
          } else {
            this.localLogin(authResult)
            resolve(authResult.idToken)
          }
        })
      })
    }

    localLogin (authResult) {
      this.idToken = authResult.idToken
      this.profile = authResult.idTokenPayload

      // Convert the JWT expiry time from seconds to milliseconds
      this.tokenExpiry = new Date(this.profile.exp * 1000)
      localStorage.setItem(tokenExpiryKey, this.tokenExpiry)
      localStorage.setItem(localStorageKey, 'true')

      // Check the store to get local user infos (if exists)
      const storeFirstName = store.state.AppActiveUser.first_name
      const storeLastName = store.state.AppActiveUser.last_name
      let displayName = ''
      if (storeFirstName != '' && storeLastName != '') {
        displayName = `${storeFirstName} ${storeLastName}`
      } else {
        displayName = this.profile.name
      }

      store.commit('UPDATE_USER_INFO', {
        displayName: displayName,
        email: this.profile.email,
        photoURL: this.profile.picture,
        providerId: this.profile.sub.substr(0, this.profile.sub.indexOf('|')),
        uid: this.profile.sub,
        encTk : utf8_to_b64(authResult.idToken) // token in b64
      })

      this.emit(loginEvent, {
        loggedIn: true,
        profile: authResult.idTokenPayload,
        state: authResult.appState || {}
      })
    }

    renewTokens () {
      // reject can be used as parameter in promise for using reject
      return new Promise((resolve) => {
        if (localStorage.getItem(localStorageKey) !== 'true') {
          // return reject("Not logged in");
        }

        webAuth.checkSession({}, (err, authResult) => {
          if (err) {
            // reject(err)
            // store.dispatch('flushUser')
          } else {
            this.localLogin(authResult)
            resolve(authResult)
          }
        })
      })
    }

    logOut () {
      // ROYBON / Log activity
      logActivity.add('app_logout', 'success')

      // prepare redirection
      const redirect = `/${store.state.portal_slug}`
      // log out  user and portal datas
      store.dispatch('flushUser')

      localStorage.removeItem(localStorageKey)
      localStorage.removeItem(tokenExpiryKey)
      localStorage.removeItem('userInfo')

      this.idToken = null
      this.tokenExpiry = null
      this.profile = null

      webAuth.logout({
        returnTo: window.location.origin + process.env.BASE_URL
      })

      this.emit(loginEvent, { loggedIn: false })

      // ROYBON / Redirection après logout
      this.$router.push(redirect).catch(() => {})

    }

    isAuthenticated () {
      return (
        new Date(Date.now()) < new Date(localStorage.getItem(tokenExpiryKey)) &&
            localStorage.getItem(localStorageKey) === 'true'
      )
    }
}

export default new AuthService()
