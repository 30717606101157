/*=========================================================================================
  File Name: app.config.js
  Description: Application congig
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL: https://rimnat.com/
==========================================================================================*/

// APP CONFIG (PROD)
const appConfig = {
  baseUrl: 'https://rimnat.com',
  contactEmail: 'contact@rimnat.com',
  apiBaseUrl: 'https://app.rimnat.com/api',
  imgBaseUrl: 'https://img.rimnat.com',
  data01BaseUrl: 'https://data01.rimnat.com',
  data01BaseUrlProxy: 'https://app.rimnat.com/data01',
  viewerBaseUrl: 'https://modelya.rimnat.com',
  appBaseUrl: 'https://app.rimnat.com',
  rimnatAppId: 'app-2022-05-02-EHN3JtFMpn',
  ggAnalyticsId: 'UA-129306678-1'
}

// Overload config for DEV|TEST env
if (process.env.NODE_ENV === 'development') {
  console.log(window.location.host.split(':')[0])

  if (window.location.host === 'app.rimnat.dev') {
    // DEV env (preprod)
    appConfig.baseUrl = 'https://rimnat.dev'
    appConfig.apiBaseUrl = 'https://api.rimnat.dev'
    appConfig.imgBaseUrl = 'https://img.rimnat.dev'
    appConfig.data01BaseUrl = 'https://data01.rimnat.dev'
    appConfig.data01BaseUrlProxy = 'https://app.rimnat.dev/data01'
    appConfig.appBaseUrl = 'https://app.rimnat.dev'
    appConfig.viewerBaseUrl = 'https://modelya.rimnat.dev'
    appConfig.ggAnalyticsId = ''
  } else if (window.location.host === 'app.rimnat.test' || window.location.host.split(':')[0] === 'localhost') {
    // TEST env (local)
    appConfig.baseUrl = 'http://rimnat.test'
    appConfig.apiBaseUrl = 'https://app.rimnat.dev/api' // Rewrite rule using proxy redirect (CORS not used)
    appConfig.imgBaseUrl = 'https://img.rimnat.dev'
    appConfig.data01BaseUrl = 'https://data01.rimnat.com'
    appConfig.data01BaseUrlProxy = 'https://app.rimnat.com/data01'
    appConfig.appBaseUrl = 'http://app.rimnat.test'
    appConfig.viewerBaseUrl = 'https://modelya.rimnat.dev'
    appConfig.ggAnalyticsId = ''
  }

}


export default appConfig
