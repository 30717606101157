/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// App config
import appConfig from '../app.config.js'
Vue.prototype.$appConfig = appConfig

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// Theme Configurations
import '../themeConfig.js'


// Firebase
import '@/firebase/firebaseConfig'


// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// RIM-Nat Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')


// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)


// Google Maps
// https://github.com/xkjyeah/vue-google-maps 
import * as VueGoogleMaps from 'vue2-google-maps'
if (process.env.NODE_ENV === 'development') {
  console.log('ROYBON / developpement mode --> Utilisation de la clé de DEV de Google Maps Api')
  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDzJxoefpREaFf22kbcnseh-2evCvfVLz4',  // clé RIM-Nat DEV
      libraries: 'places' // This is required if you use the Auto complete plug-in
    },
    installComponents: true
  })
} else {
  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCThxaQLqfcpUIkTG4-RV_d36C6ICvGWzY',  // clé RIM-Nat PROD
      libraries: 'places' // This is required if you use the Auto complete plug-in
    },
    installComponents: true
  })
}

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false

// Google Analytics
import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-129306678-1',
  router, 
  autoTracking: {
    screenview: true
  }
})

import * as VueThreejs from 'vue-threejs' // >= 0.2.0
Vue.use(VueThreejs)

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')

