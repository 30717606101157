/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'
import authConfig from '@/../auth_config.json'

import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    // Redirect to 404 page
    {
      path: '/error-404',
      redirect: '/pages/error-404'
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/',
          name: 'RootPage',
          component: () => import('./views/RootPage.vue'),
          meta: {
            pageTitle: 'Projects',
            rule: 'editor', 
            requiresLogin: true
          }
        },
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        },
        // ROYBON / public share homepage
        {
          path: '/s/:share_hash',
          name: 'ShareHome',
          component: () => import('./views/ShareHome.vue'),
          meta: {
            rule: 'editor', 
            requiresLogin: true
          }
        },
        // ROYBON / project access request validation
        {
          path: '/project_access_request/:request_hash',
          name: 'AccessRequestHome',
          component: () => import('./views/AccessRequestHome.vue'),
          meta: {
            rule: 'editor', 
            requiresLogin: true
          }
        },
        // ROYBON / diagnostic
        {
          path: '/diagnostic',
          name: 'Diagnostic',
          component: () => import('./views/Diagnostic.vue'),
          meta: {
            rule: 'editor', 
            requiresLogin: true
          }
        }
      ]
    },

    
    // =============================================================================
    // ADMIN LAYOUTS
    // =============================================================================
    {
      path: '/admin',
      redirect: '/admin/activity'
    },
    {
      // admin
      path: '/',
      component: () => import('./layouts/main/Backoffice.vue'),
      children: [
        {
          path: '/admin/activity',
          name: 'admin-activity',
          component: () => import('./views/admin/Activity.vue'),
          meta: {
            pageTitle: 'Activity',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/analytics',
          name: 'admin-analytics',
          component: () => import('./views/admin/Analytics.vue'),
          meta: {
            pageTitle: 'Analytics',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/portals',
          name: 'admin-portals',
          component: () => import('./views/admin/Portals.vue'),
          meta: {
            pageTitle: 'Portals admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/portal/:portal_slug',
          name: 'admin-portal',
          component: () => import('./views/admin/Portal.vue'),
          meta: {
            pageTitle: 'Portal admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/projects',
          name: 'admin-projects',
          component: () => import('./views/admin/Projects.vue'),
          meta: {
            pageTitle: 'Projects admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/project/:project_slug',
          name: 'admin-project',
          component: () => import('./views/admin/Project.vue'),
          meta: {
            pageTitle: 'Project admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/layers',
          name: 'admin-layers',
          component: () => import('./views/admin/Layers.vue'),
          meta: {
            pageTitle: 'Layers admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/layer/:layer_key',
          name: 'admin-layer',
          component: () => import('./views/admin/Layer.vue'),
          meta: {
            pageTitle: 'Layer admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/users',
          name: 'admin-users',
          component: () => import('./views/admin/Users.vue'),
          meta: {
            pageTitle: 'Users admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/user/:user_email',
          name: 'admin-user',
          component: () => import('./views/admin/User.vue'),
          meta: {
            pageTitle: 'User admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/shares',
          name: 'admin-shares',
          component: () => import('./views/admin/Shares.vue'),
          meta: {
            pageTitle: 'Shares admin',
            rule: 'admin', 
            requiresLogin: true
          }
        },
        {
          path: '/admin/share/:share_hash',
          name: 'admin-share',
          component: () => import('./views/admin/Share.vue'),
          meta: {
            pageTitle: 'Share admin',
            rule: 'admin', 
            requiresLogin: true
          }
        }
      ]
    },

    // =============================================================================
    // MAIN LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/project/:project_slug',
          name: 'ProjectHome',
          component: () => import('./views/ProjectHome.vue'),
          meta: {
            rule: 'editor', 
            requiresLogin: true
          }
        },

        {
          path: '/:portal_slug/shares',
          name: 'PortalShares',
          component: () => import('./views/PortalShares.vue'),
          meta: {
            pageTitle: 'Shares',
            rule: 'editor', 
            requiresLogin: true
          }
        },
        
        {
          path: '/:portal_slug/admin',
          name: 'PortalAdmin',
          component: () => import('./views/PortalAdmin.vue'),
          meta: {
            pageTitle: 'Administration',
            rule: 'editor', 
            requiresLogin: true
          }
        },

        {
          path: '/:portal_slug/layers',
          name: 'PortalLayers',
          component: () => import('./views/PortalLayers.vue'),
          meta: {
            pageTitle: 'Layers',
            rule: 'editor', 
            requiresLogin: true
          }
        },

        {
          path: '/:portal_slug/calculations',
          name: 'PortalCalculations',
          component: () => import('./views/PortalCalculations.vue'),
          meta: {
            pageTitle: 'Calculations',
            rule: 'editor', 
            requiresLogin: true
          }
        },

        {
          path: '/:portal_slug/calculations/mnt-diff',
          name: 'MntDiff',
          component: () => import('./views/MntDiff.vue'),
          meta: {
            pageTitle: 'MntDiff',
            rule: 'editor', 
            requiresLogin: true
          }
        },

        {
          path: '/:portal_slug/users',
          name: 'PortalUsers',
          component: () => import('./views/PortalUsers.vue'),
          meta: {
            pageTitle: 'Users',
            rule: 'editor', 
            requiresLogin: true
          }
        },

        // portal
        {
          path: '/:portal_slug',
          redirect: '/:portal_slug/projects'
        },
        {
          path: '/:portal_slug/projects',
          name: 'PortalProjects',
          component: () => import('./views/PortalProjects.vue'),
          meta: {
            pageTitle: 'Projects',
            rule: 'editor', 
            requiresLogin: true
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

let nbRouted = 0
router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    // if (
    //     to.path === "/pages/login" ||
    //     to.path === "/pages/forgot-password" ||
    //     to.path === "/pages/error-404" ||
    //     to.path === "/pages/error-500" ||
    //     to.path === "/pages/register" ||
    //     to.path === "/callback" ||
    //     to.path === "/pages/comingsoon" ||
    //     (auth.isAuthenticated() || firebaseCurrentUser)
    // ) {
    //     return next();
    // }

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
      if (!(auth.isAuthenticated() || firebaseCurrentUser)) {
        router.push({ path: '/pages/login', query: { to: to.path } })
      }
    }

    // Recall auth every XX routed pages
    if (nbRouted == authConfig.recallEveryPages) {
      nbRouted = 0
      auth.renewTokens()
    }
    nbRouted++

    return next()
    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

  })

})

export default router