/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
==========================================================================================*/


import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from './i18nData'

const defaultLanguage = 'fr'

Vue.use(VueI18n)

const i18n = new VueI18n({
  // language config
  locale: defaultLanguage,
  messages: i18nData,
  cookie: 'user_lang',
  queryParameter: 'user_lang'
})
export default i18n
